import payload_plugin_pf78XtTrFS from "/app/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.28.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4Xp5S9GuRN from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._exgb62do5u3tmlfnte55s4h264/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LoR1yQ7yOi from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._exgb62do5u3tmlfnte55s4h264/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RNme7WW3Sr from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._exgb62do5u3tmlfnte55s4h264/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rdB1P5iYqj from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._exgb62do5u3tmlfnte55s4h264/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_CTX7gO1vf2 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._exgb62do5u3tmlfnte55s4h264/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Y9z9yDzydc from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._exgb62do5u3tmlfnte55s4h264/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_BUvOsB1jbX from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._exgb62do5u3tmlfnte55s4h264/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_gw7c2MqCQh from "/app/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.28.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_bYDF5fw8J6 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._exgb62do5u3tmlfnte55s4h264/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_txYXxYftVa from "/app/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_tDbAXz79mh from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_sass@1.82._tkgz5liea4ihz4invvqld4btae/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_zoF4yPIBkC from "/app/node_modules/.pnpm/nuxt-socket-io@3.0.13/node_modules/nuxt-socket-io/lib/plugin.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_lEBNQctN0c from "/app/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_RAu34xDmgX from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.3_@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7_l6aahl6nidmmurkufz4gxave24/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_jThAyAlx0A from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_reac_4ln3qgyngminhdfkbbtiby5mru/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_p9KGT4uXma from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.28.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import _01_brand_qsKxBO1fpE from "/app/plugins/01.brand.ts";
import _02_inject_dynamic_css_variables_client_UQ9uISccVp from "/app/plugins/02.inject-dynamic-css-variables.client.ts";
import _03_crisp_P9wmxnXAa7 from "/app/plugins/03.crisp.ts";
import _04_socket_yt7UzoOqjD from "/app/plugins/04.socket.ts";
import quill_editor_client_tupKYLQr8r from "/app/plugins/quill-editor.client.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import universal_design_system_client_NoP6UuzpPW from "/app/plugins/universal-design-system.client.ts";
import vue_draggable_OKRqLUiGtd from "/app/plugins/vue-draggable.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
export default [
  payload_plugin_pf78XtTrFS,
  revive_payload_client_4Xp5S9GuRN,
  unhead_LoR1yQ7yOi,
  router_RNme7WW3Sr,
  payload_client_rdB1P5iYqj,
  navigation_repaint_client_CTX7gO1vf2,
  check_outdated_build_client_Y9z9yDzydc,
  chunk_reload_client_BUvOsB1jbX,
  plugin_vue3_gw7c2MqCQh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bYDF5fw8J6,
  plugin_client_txYXxYftVa,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_tDbAXz79mh,
  plugin_zoF4yPIBkC,
  plugin_wy0B721ODc,
  plugin_client_lEBNQctN0c,
  plugin_RAu34xDmgX,
  plugin_jThAyAlx0A,
  plugin_p9KGT4uXma,
  _01_brand_qsKxBO1fpE,
  _02_inject_dynamic_css_variables_client_UQ9uISccVp,
  _03_crisp_P9wmxnXAa7,
  _04_socket_yt7UzoOqjD,
  quill_editor_client_tupKYLQr8r,
  sentry_3AyO8nEfhE,
  universal_design_system_client_NoP6UuzpPW,
  vue_draggable_OKRqLUiGtd,
  vue_final_modal_pML93k5qcp
]