import type { ISocietyPartner, UpdatePartner } from "@silexpert/core";
import { Code } from "@silexpert/core";

type PartnerState = {
  partners: ISocietyPartner[];
};

export const usePartnerStore = defineStore("partner", {
  state: () =>
    ref<PartnerState>({
      partners: [],
    }),
  getters: {
    getPartnersForAnnotating: (state): ISocietyPartner[] => {
      const partners = state.partners ?? [];
      const partnersNotDeleted = partners.filter((p) => !isDefined(p.deletedAt));
      return partnersNotDeleted;
    },
  },
  actions: {
    reset() {
      this.partners = [];
    },
    async fetchSocietyPartners() {
      try {
        this.partners = await $silex().partner.getAll({ includeDeletedPartner: true });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async createPartner(partnerForm: ISocietyPartner) {
      await $silex().partner.create(partnerForm);
      await this.fetchSocietyPartners();
    },
    async updatePartner(id: number, partnerForm: UpdatePartner) {
      await $silex().partner.update(id, partnerForm);
      await this.fetchSocietyPartners();
    },
    async removePartner(societyPartnerId: number) {
      try {
        await $silex().partner.delete(societyPartnerId);
      } catch (error) {
        let message = "Une erreur est survenue lors de la suppression";
        // @ts-expect-error
        if (error.response.data.code === Code.constraintKeyError) {
          message =
            "Cette personne est associé à un véhicule. Merci de supprimer le véhicule ou de modifier le collaborateur.";
        }

        $notifier().open({ type: "error", content: message });
      }

      await this.fetchSocietyPartners();
    },
    setPartner(itemId: number, item: ISocietyPartner) {
      const items = this.partners ?? [];
      const indexToChange = items.findIndex((a) => a.id === itemId);

      if (indexToChange > -1 && isDefined(this.partners)) {
        this.partners[indexToChange] = item;
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
