import type {
  BasePaginate,
  IRegistrationDetail,
  ReadRegistration,
  ReadFormattedAccountingTransaction,
  Das2ThirdParty,
} from "@silexpert/core";
import { CerfaType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";

const dayjs = useDayjs();

export type DasCreationData = {
  mode: "read" | "creation";
  year: string;
  transactions: (ReadFormattedAccountingTransaction & {
    idThirdParty: number | null;
    isSelected: boolean;
  })[];
  thirdParties: (Partial<Das2ThirdParty> & { id: number })[];
  isLoading: boolean;
  selectedMergeAccountId: number | null;
};

export type Das2QueryProperties = {
  itemId: number | null | undefined;
  startDate: string | null;
  endDate: string | null;
  page: number;
  limit: number;
  idExercice: number | null;
};

export const defaultDas2QueryProperties = ref<Das2QueryProperties>({
  itemId: null,
  startDate: null,
  endDate: null,
  page: 1,
  limit: 25,
  idExercice: null,
});

const defaultDataForm: DasCreationData = {
  mode: "read" as "read" | "creation",
  year: dayjs().format("YYYY"),
  transactions: [],
  thirdParties: [],
  isLoading: false,
  selectedMergeAccountId: null,
};

export const useDas2Store = defineStore("das2", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultDas2QueryProperties.value) as Das2QueryProperties,
      items: null as (ReadRegistration & Loadable)[] | null,
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      isLoading: false as boolean,
      hasAnyItem: null as boolean | null,
      itemDetails: null as IRegistrationDetail | null,
      dataForm: cloneDeep(defaultDataForm),
      controllers: { das2: null, hasItem: null } as {
        das2: AbortController | null;
        hasItem: AbortController | null;
      },
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = defaultDas2QueryProperties.value;
      this.items = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
      this.itemDetails = null;
      this.dataForm = cloneDeep(defaultDataForm);
    },
    async fetchDeclarationItems() {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;
      this.isLoading = true;

      const { startDate, endDate, page, limit } = this.queryProperties;

      if (this.controllers.das2 && this.controllers.das2.signal) {
        await this.controllers.das2.abort();
        this.controllers.das2 = null;
      }
      this.controllers.das2 = new AbortController();

      let response: any = null;

      await $silex()
        .registration.getPaginated(
          idSociety!,
          {
            page,
            limit,
            startDate: startDate ?? undefined,
            endDate: endDate ?? undefined,
            idCerfaType: [CerfaType.CERFA_DAS2_2023],
          },
          this.controllers.das2.signal,
        )
        .then((res: BasePaginate<ReadRegistration>) => (response = res))
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          response = [];
        });

      this.isLoading = false;

      const data = response?.data ?? [];

      this.items = data.map((item: ReadRegistration) => {
        return {
          ...item,
          isLoading: false,
        };
      });

      this.maxPages = response?.maxPages ?? 0;

      this.currentPage = response?.currentPage ?? 0;

      this.totalItems = response?.totalItems ?? 0;
    },
    async fetchHasAnyItem() {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;
      this.hasAnyItem = null;

      if (this.controllers.hasItem && this.controllers.hasItem.signal)
        this.controllers.hasItem.abort();
      this.controllers.hasItem = new AbortController();

      let response: any = null;

      await $silex()
        .registration.getPaginated(
          idSociety,
          {
            page: 1,
            limit: 1,
            idCerfaType: [CerfaType.CERFA_DAS2_2023],
          },
          this.controllers.hasItem.signal,
        )
        .then((res: BasePaginate<ReadRegistration>) => (response = res));

      const totalItems = response?.totalItems ?? 0;

      this.hasAnyItem = totalItems > 0;
    },
    resetCreation() {
      this.dataForm = cloneDeep(defaultDataForm);
    },
    setItem(vatItemId: number, vatItem: ReadRegistration & Loadable) {
      const item = this.items ?? [];
      const itemIndexToChange = item.findIndex((a) => a.id === vatItemId);

      if (itemIndexToChange > -1 && isDefined(this.items)) {
        this.items[itemIndexToChange] = vatItem;
      }
    },
    async teledeclareDas2(payload: {
      idRegistration: number;
      amountToPay: number;
      hasOga: boolean;
    }) {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;

      const das2Declaration = this.items?.find((i) => i.id === payload.idRegistration);

      if (!das2Declaration) {
        $notifier().open({
          type: "error",
          content: "Aucune déclaration trouvée. Veuillez réessayer plus tard.",
        });
        return;
      }

      await $silex()
        .das // @ts-expect-error
        .declare(idSociety, das2Declaration.id!)
        .then((res: ReadRegistration) => {
          // set declaration
          this.setItem(payload.idRegistration, { ...res, isLoading: false });
          $notifier().open({ type: "success", content: "La télédaclaration a été envoyée" });
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          throw error;
        });
    },
    async fetchDasTransactions() {
      this.dataForm.isLoading = true;
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;
      const { year } = this.dataForm;

      let response: any = [];
      const payload = {
        startDate: `${year}-01-01`,
        endDate: `${year}-12-31`,
      };
      await $silex()
        .das.getAllTransactions(idSociety!, payload)
        .then(
          (res: ReadFormattedAccountingTransaction[]) =>
            (response = res.map((item: ReadFormattedAccountingTransaction) => {
              return {
                ...item,
                idThirdParty: null,
                isSelected: false,
              };
            })),
        )
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          response = [];
        });

      this.dataForm.transactions = response;
      this.dataForm.isLoading = false;
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
