import type { ILoan } from "@silexpert/core";

type LoanState = {
  loans: ILoan[];
};

export const useLoanStore = defineStore("loan", {
  state: () =>
    ref<LoanState>({
      loans: [],
    }),
  actions: {
    reset() {
      this.loans = [];
    },
    async fetchLoans() {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;

      await $silex()
        .loan.getAll(idSociety)
        .then((res) => (this.loans = res))
        .catch((error) => $notifier().open({ type: "error", content: apiErrorToString(error) }));
    },
    async delete(id: number) {
      await $silex()
        .loan.delete(id)
        .then(async () => await this.fetchLoans())
        .catch((error) => $notifier().open({ type: "error", content: apiErrorToString(error) }));
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
