import type { ReadUpcomingInvoice } from "@silexpert/core";
import type Stripe from "stripe";

type SubscriptionState = {
  subscription: Stripe.Response<Stripe.Subscription> | null;
  upcomingInvoice: ReadUpcomingInvoice | null;
  isRestricted: boolean;
};

export const useSubscriptionStore = defineStore("subscription", {
  state: () =>
    ref<SubscriptionState>({
      subscription: null,
      upcomingInvoice: null,
      isRestricted: false,
    }),
  getters: {
    // Pour savoir si les features d'abonnement sont actives
    canSubscribe: (): boolean => {
      const brands = useBrandsComposable();
      return brands.isComptalib();
    },
    hasCurrentSubscription: (state: SubscriptionState): boolean => {
      return Boolean(
        state.subscription &&
          ["active", "past_due", "trialing", "unpaid"].includes(state.subscription.status),
      );
    },
    stripeCustomerId: (state): string | null => {
      return typeof state.subscription?.customer === "string"
        ? state.subscription.customer
        : (state.subscription?.customer?.id ?? null);
    },
    isInErrorState: (state): boolean => {
      return Boolean(
        state.subscription && ["past_due", "unpaid"].includes(state.subscription.status),
      );
    },
    status: (state): Stripe.Subscription.Status | null => {
      return state.subscription?.status || null;
    },
  },
  actions: {
    reset() {
      this.subscription = null;
      this.upcomingInvoice = null;
      this.isRestricted = false;
    },
    async fetch(): Promise<void> {
      try {
        const societyStore = useSocietyStore();
        this.subscription = await $silex().subscription.getBySociety(societyStore.society!.id!);
      } catch (error) {
        // @ts-expect-error
        if (error?.response?.data?.statusCode === 404) {
          this.subscription = null;
        } else {
          $notifier().open({ content: apiErrorToString(error) });
        }
      }
    },
    async fetchUpcomingInvoice(): Promise<void> {
      try {
        if (!this.subscription?.id || !this.stripeCustomerId) {
          this.upcomingInvoice = null;
        } else {
          this.upcomingInvoice = await $silex().subscription.retrieveUpcomingInvoice({
            customerId: this.stripeCustomerId,
            subscriptionId: this.subscription?.id,
          });
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    // async fetchPaymentMethod(): Promise<void> {
    //   try {
    //   } catch (error) {
    //     $notifier().open({ content: apiErrorToString(error) });
    //   }
    // },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
