import type {
  IBalanceSheet,
  IBalanceSheetModule,
  IGedFile,
  ISocietyConfigBalanceSheet,
  ReadRegistration,
  ReadBalanceSheetConsistency,
} from "@silexpert/core";
import {
  CerfaType,
  DocumentConfigurationType,
  StateDepositDocument,
  ConsistencyState,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";

export type BalanceSheetQueryProperties = {
  itemId: number | null | undefined;
  startDate: string | null;
  endDate: string | null;
  page: number;
  limit: number;
};

export const defaultBalanceSheetQueryProperties = ref<BalanceSheetQueryProperties>({
  itemId: null,
  startDate: null,
  endDate: null,
  page: 1,
  limit: 25,
});

type BalanceSheetState = {
  controllers: { fetch: AbortController | null };
  exerciceBalanceSheets: IBalanceSheet[];
  isLoadingBalanceSheets: boolean;
  isLoading: boolean;
  isLoadingDocuments: boolean;
  isLoadingSigns: boolean;
  current: IBalanceSheet | null;
  documents: ISocietyConfigBalanceSheet[];
  files: IGedFile[];
  signs: IGedFile[];
  modules: IBalanceSheetModule[];
  registrations: ReadRegistration[];
  queryProperties: BalanceSheetQueryProperties;
  data: (ReadRegistration & Loadable)[];
  currentPage: number | null;
  maxPages: number | null;
  totalItems: number | null;
  hasAnyItem: null;
  consistency: {
    isLoading: boolean;
    data: ReadBalanceSheetConsistency[];
  };
};

export const useBalanceSheetStore = defineStore("balanceSheet", {
  state: () =>
    ref<BalanceSheetState>({
      controllers: { fetch: null },
      exerciceBalanceSheets: [],
      isLoadingBalanceSheets: false,
      isLoadingDocuments: false,
      isLoadingSigns: false,
      isLoading: false,
      current: null,
      documents: [],
      signs: [],
      files: [],
      modules: [],
      registrations: [],
      queryProperties: cloneDeep(defaultBalanceSheetQueryProperties.value),
      data: [],
      currentPage: null,
      maxPages: null,
      totalItems: null,
      hasAnyItem: null, // Not managed, only for types
      consistency: {
        isLoading: false,
        data: [],
      },
    }),
  getters: {
    currentRegistrations: (state: BalanceSheetState): ReadRegistration[] => {
      const registrationsByType: { [key: string]: ReadRegistration[] } = state.registrations.reduce(
        (r: any, v, _i, _a, k = v.idCerfaType.toString()) => {
          (r[k] || (r[k] = [])).push(v);
          return r;
        },
        {},
      );
      const results: ReadRegistration[] = [];
      for (const index in registrationsByType) {
        results.push(
          registrationsByType[index].sort(
            (a, b) =>
              Number(new Date(b?.createdAt ?? "1970-01-01")) -
              Number(new Date(a?.createdAt ?? "1970-01-01")),
          )[0],
        );
      }

      // Fix : trop de cerfa retourné car les informations de la société ont changé entre 2 générations
      if (results.length > 2) {
        const fixedResults: ReadRegistration[] = [];

        const balanceSheetComposable = useBalanceSheetComposable();
        const cerfaType = balanceSheetComposable.cerfaToFill.value;

        const declaration = results.find((r) => r.idCerfaType === cerfaType.declaration);
        const annex = results.find((r) => r.idCerfaType === cerfaType.annex);

        if (declaration) {
          fixedResults.push(declaration);
        }

        if (annex) {
          fixedResults.push(annex);
        }

        return fixedResults;
      }

      return results;
    },
    consistencyErrors(): ReadBalanceSheetConsistency[] {
      return this.consistency.data.filter((d) => d.state === ConsistencyState.INVALID);
    },
  },
  actions: {
    reset() {
      this.exerciceBalanceSheets = [];
      this.isLoadingBalanceSheets = false;
      this.isLoadingDocuments = false;
      this.isLoadingSigns = false;
      this.current = null;
      this.documents = [];
      this.signs = [];
      this.files = [];
      this.modules = [];
      this.registrations = [];
      this.queryProperties = cloneDeep(defaultBalanceSheetQueryProperties.value);
      this.data = [];
      this.isLoading = false;
      this.currentPage = null;
      this.maxPages = null;
      this.totalItems = null;
      this.controllers = { fetch: null };
      this.consistency = {
        isLoading: false,
        data: [],
      };
    },
    async fetchConsistency(idExercice: number) {
      try {
        this.consistency.isLoading = true;
        this.consistency.data = await $silex().balanceSheet.getConsistency(idExercice);
      } catch (error) {
        this.consistency.data = [];
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      } finally {
        this.consistency.isLoading = false;
      }
    },
    async fetchAllForExercice(idExercice: number) {
      try {
        this.isLoadingBalanceSheets = true;
        this.exerciceBalanceSheets = await $silex().exercice.getAllBalanceSheets(idExercice);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoadingBalanceSheets = false;
    },
    async fetchDocuments() {
      if (!this.current) {
        this.documents = [];
        return;
      }
      try {
        const societyStore = useSocietyStore();

        this.isLoadingDocuments = true;
        this.documents = (
          await $silex().societyConfigBalanceSheet.getAll(societyStore.society!.id!, {
            idBalanceSheet: this.current!.id!,
            configurationType: DocumentConfigurationType.BALANCESHEET,
          })
        ).filter((file) => file.state === StateDepositDocument.ASKED);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoadingDocuments = false;
    },
    async fetchSigns() {
      if (!this.current) {
        this.signs = [];
        return;
      }
      try {
        const societyStore = useSocietyStore();

        this.isLoadingSigns = true;
        const response = await $silex().library.getAll({
          idSociety: societyStore.society!.id!,
          idBalanceSheet: this.current!.id!,
          include: ["fileFromGed", "customerSignature"],
        });

        this.files = response;
        this.signs = response.filter((f) => f.customerSignature);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoadingSigns = false;
    },
    async fetchModuleStates() {
      try {
        const societyStore = useSocietyStore();
        this.modules = await $silex().balanceSheetModule.getBalanceSheetModules(
          societyStore.society!.id!,
        );
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchRegistrations() {
      try {
        const societyStore = useSocietyStore();
        const exerciceStore = useExerciceStore();

        if (this.controllers.fetch && this.controllers.fetch.signal) {
          await this.controllers.fetch.abort();
          this.controllers.fetch = null;
        }
        this.controllers.fetch = new AbortController();

        this.registrations = (
          await $silex().registration.getAll(societyStore.society!.id!, {
            idExercice: exerciceStore.current!.id,
          })
        ).filter((registration) =>
          [
            CerfaType.CERFA_2031,
            CerfaType.CERFA_2035,
            CerfaType.CERFA_2065,
            CerfaType.CERFA_2572_2022,
            CerfaType.CERFA_2572_2023,
          ].includes(registration.idCerfaType),
        );
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchPaginatedRegistrations() {
      try {
        const societyStore = useSocietyStore();
        const exerciceStore = useExerciceStore();

        const { startDate, endDate, page, limit } = this.queryProperties;
        const currentExercice = exerciceStore.exercices.find(
          (e) => e.startDate === startDate && e.endDate === endDate,
        );

        if (!currentExercice)
          return $notifier().open({
            type: "error",
            content:
              "Veuillez sélectionner une période correspondant à un exercice entier de la société",
          });

        if (this.controllers.fetch && this.controllers.fetch.signal) {
          await this.controllers.fetch.abort();
          this.controllers.fetch = null;
        }
        this.controllers.fetch = new AbortController();

        this.isLoading = true;
        const registrations = await $silex().registration.getPaginated(
          societyStore.society!.id!,
          {
            idExercice: currentExercice.id,
            page,
            limit,
            idCerfaType: [
              CerfaType.CERFA_2031,
              CerfaType.CERFA_2035,
              CerfaType.CERFA_2065,
              CerfaType.CERFA_2572_2022,
              CerfaType.CERFA_2572_2023,
            ],
          },
          this.controllers.fetch.signal,
        );

        this.data = registrations.data.map((d) => ({ ...d, isLoading: false }));
        this.maxPages = registrations.maxPages;
        this.totalItems = registrations.totalItems;
        this.currentPage = registrations.currentPage;
      } catch (error: any) {
        if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
