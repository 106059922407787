import type { ReadSalariesFile } from "@silexpert/core";

type SalaryFileState = {
  isLoading: boolean;
  salaryFiles: ReadSalariesFile[];
};

export const useSalaryFileStore = defineStore("salaryFile", {
  state: () =>
    ref<SalaryFileState>({
      salaryFiles: [],
      isLoading: false,
    }),
  actions: {
    reset() {
      this.salaryFiles = [];
      this.isLoading = false;
    },
    async fetchSalaryFiles(idExercice?: number) {
      try {
        this.isLoading = true;
        const exerciceStore = useExerciceStore();

        const exerciceId = idExercice || exerciceStore.current!.id!;
        this.salaryFiles = await $silex().salariesFile.getAll({ idExercice: exerciceId });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
    async delete(id: number) {
      try {
        await $silex().salariesFile.delete({ idSalarieFile: id });
        await this.fetchSalaryFiles();
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
