import type { IUser, ReadByExternalId } from "@silexpert/core";
import { Role, RoleAllowedToLogInAsClient } from "@silexpert/core";
import { useExerciceStore } from "./exercice";
import { useUsersStore } from "./users";
import { useFecStore } from "./fec";
import { useIncomeAllocationStore } from "./incomeAllocation";
import { useManualEntryStore } from "./manualEntry";
import { useConnectorStore } from "./connector";

export const useAuthentificationStore = defineStore("authentification", {
  state: () =>
    ref<{ identifier: string | null }>({
      identifier: null,
    }),
  getters: {},
  actions: {
    reset() {},
    async logInUser(user: IUser, isConnectingFromAnAdministrator?: boolean, redirect?: string) {
      const userStore = useUsersStore();
      const societyStore = useSocietyStore();
      const exerciceStore = useExerciceStore();
      const prestationStore = usePrestationStore();
      const subscriptionStore = useSubscriptionStore();
      const roleComposable = useRolesComposable();

      window.$socket.on(`user_${user.id}_connection`, (event: { idSociety: number }) => {
        // Lorsque l'utilisateur se connecte sur 2 sociétés différentes (via un onglet en navigation privé par exemple)
        // on force la déconnexion des sociétés inactives pour éviter des soucis côté API, notamment avec @CurrentSociety()
        if (isDefined(societyStore.society?.id) && societyStore.society.id !== event.idSociety) {
          $notifier().open({
            content: "Session expirée, vous êtes désormais connecté depuis un autre navigateur.",
            duration: 6000,
          });
          this.logOut();
        }
      });

      try {
        if (!user)
          return $notifier().open({
            type: "error",
            content: "Une erreur est survenue lors de la récupération de l'utilisateur",
          });

        userStore.setUser(user);

        if (
          !isConnectingFromAnAdministrator &&
          roleComposable.hasOneOfTheseRoles([Role.PARTNERED_ACCOUNTANT])
        ) {
          return await navigateTo(redirect || `/expert/performances`);
        }
        if (
          !isConnectingFromAnAdministrator &&
          roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient)
        ) {
          return await navigateTo(redirect || `/admin/societies`);
        }

        let idSociety = userStore.currentSocietyId;

        if (isNotDefined(idSociety)) {
          if (userStore.societies.length) {
            // On défini la société par défaut à la première trouvée
            idSociety = userStore.societies[0]!.id!;
            userStore.setUser(user, idSociety);
          } else {
            return $notifier().open({
              type: "error",
              content: `Société par défaut introuvable pour l'utilisateur #${
                userStore.user?.id ?? null
              }`,
            });
          }
        }

        // Chargement de toutes les informations nécessaires à un affichage direct
        await Promise.all([
          societyStore.fetchSociety(idSociety),
          societyStore.fetchConfig(idSociety),
          exerciceStore.fetchExercices(idSociety),
          prestationStore.fetchSocietyPrestations(idSociety),
        ]);

        // Chargement des informations nécessaires, nécessitant des informations déjà chargées
        await Promise.all([
          userStore.fetchSocieties(),
          subscriptionStore.canSubscribe
            ? subscriptionStore.fetch()
            : new Promise((resolve) => resolve(true)),
        ]);

        await this.fetchAllData(idSociety);

        await navigateTo(redirect ?? userStore.homePage);
      } catch (error) {
        $notifier().open({
          type: "error",
          content: apiErrorToString(error),
        });
      }
    },
    async logInByUuid(uuid: string): Promise<ReadByExternalId> {
      if (!this.identifier) {
        this.identifier = self.crypto.randomUUID();
      }

      const infos = await $silex().authenticate.byExternalId(uuid, { identifier: this.identifier });
      const { signIn } = useAuth();

      window.$socket.on(
        `identifier_${this.identifier}_connection`,
        (event: { idSociety: number }) => {
          // Lorsque l'utilisateur se connecte sur 2 sociétés différentes (via un onglet en navigation privé par exemple)
          // on force la déconnexion des sociétés inactives pour éviter des soucis côté API, notamment avec @CurrentSociety()
          if (isDefined(societyStore.society?.id) && societyStore.society.id !== event.idSociety) {
            $notifier().open({
              content: "Session expirée, vous êtes désormais connecté depuis un autre navigateur.",
              duration: 6000,
            });
            this.logOut();
          }
        },
      );

      await signIn(
        {
          login: "fromApp",
          password: "fromApp",
          token: infos.token.replace("bearer ", ""),
        },
        { redirect: false },
      );

      const societyStore = useSocietyStore();

      await societyStore.fetchSociety(infos.idSociety);
      await societyStore.fetchSocietyDetail();

      return infos;
    },
    async fetchAllData(idSociety: number) {
      const userStore = useUsersStore();
      const societyStore = useSocietyStore();
      const exerciceStore = useExerciceStore();
      const unlockedStore = useUnlockedStore();
      const notificationStore = useNotificationStore();
      const serverInfoStore = useServerInfoStore();
      const bankStore = useBankStore();
      const thirdPartyStore = useThirdPartiesStore();
      const saleStore = useSalesStore();
      const categoryStore = useCategoryStore();
      const connectorStore = useConnectorStore();

      const promises = [
        exerciceStore.setCurrentExerciceIfEmpty(),
        unlockedStore.fetchAll(idSociety),
        societyStore.fetchVisualizedInformations(idSociety),
        notificationStore.fetchSocietyAppNotifications(idSociety),
        serverInfoStore.fetchVats(),
        bankStore.fetchBankAccounts(idSociety),
        categoryStore.fetchSocietyCategories(userStore.currentSocietyId!),
        serverInfoStore.initializeServerInfo(),
        thirdPartyStore.fetchThirdPartiesAndGenericThirdParties(),
        bankStore.fetchRules(),
        saleStore.fetchSettings(),
        userStore.fetchContactHistory(),
        connectorStore.fetchConnectors(),
        societyStore.fetchAccountant(),
      ];

      // Permet d'initialiser les filtres par période fiscale
      if (!societyStore.isCashBased) {
        promises.push(unlockedStore.fetchSaleDocuments(idSociety));
        promises.push(unlockedStore.fetchPurchaseDocuments(idSociety));
      }
      if (categoryStore.types.length === 0) {
        promises.push(categoryStore.fetchCategoryTypes());
      }

      await Promise.allSettled(promises);
    },
    resetAll(params?: { shouldKeepUser?: boolean; shouldKeepUserAndSociety?: boolean }) {
      const societyStore = useSocietyStore();
      const userStore = useUsersStore();

      const society = societyStore.society;
      const user = userStore.user;

      if (society && user) {
        // Suppression des sockets suivis
        const events: string[] = [
          `ocr-invoice-process-from-revision-finished-${user.id}-${society.id}`,
          `ocr-invoice-process-finished-${user.id}-${society.id}`,
        ];
        try {
          events.forEach((e) => {
            window.$socket.off(e);
          });
        } catch (error) {
          console.error("👀 Socket unsubscribe error : ", error);
        }
      }

      const exerciceStore = useExerciceStore();
      const unlockedStore = useUnlockedStore();
      const prestationStore = usePrestationStore();
      const notificationStore = useNotificationStore();
      const vehicleStore = useVehicleStore();
      const bankStore = useBankStore();
      const thirdPartyStore = useThirdPartiesStore();
      const partnerStore = usePartnerStore();
      const immobilizationStore = useImmobilizationStore();
      const balanceSheetStore = useBalanceSheetStore();
      const balanceSheetModulesStore = useBalanceSheetModulesStore();
      const fecStore = useFecStore();
      const categoryStore = useCategoryStore();
      const loanStore = useLoanStore();
      const salaryFileStore = useSalaryFileStore();
      const incomeAllocationStore = useIncomeAllocationStore();
      const socialCapitalStore = useSocialCapitalStore();
      const manualEntryStore = useManualEntryStore();
      const dubiousDebtStore = useDubiousDebtStore();
      const inventoryStockStore = useInventoryStockStore();
      const vatControlStore = useVatControlStore();
      const companyTaxStore = useCompanyTaxStore();
      const selfEmployedStore = useSelfEmployedStore();
      const kilometricAllowanceStore = useKilometricAllowanceStore();
      const businessCreationStore = useBusinessCreationStore();
      const subscriptionStore = useSubscriptionStore();
      const auxiliaryLedgerStore = useAuxiliaryLedgerStore();
      const generalLedgerStore = useGeneralLedgerStore();
      const balanceStore = useBalanceStore();
      const employeeStore = useEmployeesStore();
      const saleStore = useSalesStore();
      const balanceSheetFormStore = useBalanceSheetFormStore();
      const paymentStore = usePaymentStore();
      const settingsAccountingPlanStore = useSettingAccountingPlanStore();
      const connectorStore = useConnectorStore();
      const dashboardStore = useDashboardStore();
      const purchaseStore = usePurchasesStore();
      const libraryStore = useLibraryStore();
      const partnerAccountantsStore = usePartnerAccountantsStore();
      const vatStore = useVatStore();
      const das2Store = useDas2Store();
      const productStore = useProductStore();
      const accessStore = useAccessPageStore();
      const zTicketStore = useZTicketsStore();
      const preComptaStore = usePreComptaStore();
      const pendingPointsStore = usePendingPointsStore();
      const revisionGuideStore = useRevisionGuideStore();
      const balanceSheetDocumentsStore = useBalanceSheetDocumentsStore();
      const specificAccountingPlanStore = useSpecificAccountingPlanStore();
      const persoAccountingPlanState = usePersoAccountingPlanStore();
      const genericAccountStore = useGenericAccountStore();
      const revisionAccountStore = useRevisionStore();
      const revisionCustomerStore = useRevisionCustomerStore();
      const revisionProviderStore = useRevisionProviderStore();
      const customerProviderStore = useCustomerProviderStore();
      const expertAccountantStore = useExpertAccountantStore();
      const registrationStore = useRegistrationStore();
      const recoveryStore = useRecoveryStore();
      const commonStore = useCommonStore();
      const settingsStore = useSettingStore();

      if (!params?.shouldKeepUserAndSociety) {
        societyStore.lightReset();
        if (!params?.shouldKeepUser) {
          societyStore.reset();
          userStore.reset();
        } else {
          societyStore.reset();
          userStore.societies = [];
        }
      }

      /**
       * Ne pas reset les éléments globaux qui sont communs à toute société / utilisateur
       * pour éviter de les fetch à chaque connexion
       */
      this.reset();
      notificationStore.reset();
      exerciceStore.reset();
      unlockedStore.reset();
      prestationStore.reset();
      vehicleStore.reset();
      bankStore.reset();
      thirdPartyStore.reset();
      partnerStore.reset();
      immobilizationStore.reset();
      balanceSheetStore.reset();
      balanceSheetModulesStore.reset();
      fecStore.reset();
      categoryStore.reset();
      loanStore.reset();
      salaryFileStore.reset();
      incomeAllocationStore.reset();
      socialCapitalStore.reset();
      manualEntryStore.reset();
      dubiousDebtStore.reset();
      inventoryStockStore.reset();
      vatControlStore.reset();
      companyTaxStore.reset();
      selfEmployedStore.reset();
      kilometricAllowanceStore.reset();
      subscriptionStore.reset();
      businessCreationStore.reset();
      auxiliaryLedgerStore.reset();
      generalLedgerStore.reset();
      balanceStore.reset();
      employeeStore.reset();
      saleStore.reset();
      balanceSheetFormStore.reset();
      paymentStore.reset();
      settingsAccountingPlanStore.reset();
      connectorStore.reset();
      dashboardStore.reset();
      purchaseStore.reset();
      libraryStore.reset();
      partnerAccountantsStore.reset();
      vatStore.reset();
      das2Store.reset();
      productStore.reset();
      accessStore.reset();
      zTicketStore.reset();
      preComptaStore.reset();
      pendingPointsStore.reset();
      revisionGuideStore.reset();
      balanceSheetDocumentsStore.reset();
      specificAccountingPlanStore.reset();
      persoAccountingPlanState.reset();
      genericAccountStore.reset();
      revisionAccountStore.reset();
      revisionCustomerStore.reset();
      revisionProviderStore.reset();
      customerProviderStore.reset();
      expertAccountantStore.reset();
      registrationStore.reset();
      recoveryStore.reset();
      commonStore.reset();
      settingsStore.reset();
    },
    async logOut() {
      const brands = useBrandsComposable();

      if (brands.isComptaStart()) {
        await navigateTo(`/sso`);
      } else {
        await navigateTo(`/login`);
      }

      this.resetAll();
      const { signOut } = useAuth();
      signOut();
    },
    async connectTo(idSociety: number, redirect?: string) {
      this.resetAll({ shouldKeepUser: true });
      const { token, user } = await $silex().authenticate.connectAs(idSociety);
      const { setToken } = useAuthState();
      setToken(token);

      await this.logInUser(user!, true, redirect);
    },
    async logOutToAdmin(redirect: string) {
      await this.resetAll({ shouldKeepUser: true });

      const userStore = useUsersStore();
      await this.logInUser(userStore.user!, false, redirect);
    },
    async connectFromERP(params: {
      token: string;
      action: string;
      idSociety: number;
      startDate?: string;
      endDate?: string;
      idBankAccount?: string;
      idExercice?: string;
    }) {
      const roleComposable = useRolesComposable();
      const userStore = useUsersStore();

      const { action, idSociety, startDate, endDate, idBankAccount, idExercice } = params;

      let isConnectingFromAnAdministrator = false;
      let redirect = "";

      const user = await $silex().user.getInfo();
      userStore.setUser(user);

      if (!roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient)) {
        return $notifier().open({
          type: "error",
          content: "Vous n'avez pas la permission requise",
          duration: 3000,
        });
      }

      switch (action) {
        case "society_edition":
          redirect = `/admin/societies/${idSociety}/config/information`;
          break;
        case "as_client":
          isConnectingFromAnAdministrator = true;
          break;
        case "society_revision":
          redirect = `/admin/societies/${idSociety}/accounting/revisionaccount`;
          break;
        case "society_vat":
          redirect = `/admin/societies/${idSociety}/declaration/vat`;
          break;
        case "society_precompta":
          redirect = `/admin/societies/${idSociety}/config/precompta`;
          break;
        case "society_pendingpoints":
          redirect = `/admin/societies/${idSociety}/accounting/pendingpoints`;
          break;
        default:
          break;
      }

      if (startDate || endDate || idBankAccount || idExercice) {
        const queries = new URLSearchParams({
          ...(startDate ? { startDate: `"${startDate}"` } : {}),
          ...(idExercice ? { idExercice: `"${idExercice}"` } : {}),
          ...(endDate ? { endDate: `"${endDate}"` } : {}),
          ...(idBankAccount ? { idBankAccount: `${idBankAccount}` } : {}),
        }).toString();
        redirect = `${redirect}?${queries}`;
      }

      if (!isConnectingFromAnAdministrator) {
        this.resetAll({ shouldKeepUser: true });
        await this.logInUser(user, true, `${redirect}#forceRefresh`);
      } else {
        await this.connectTo(idSociety);
      }
    },
    async connectFromSSO() {
      const user = await $silex().user.getInfo();
      await this.logInUser(user, false);
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
