import type {
  IEmployee,
  ISheetMonthSchedule,
  UpdateOrCreateWorkingSchedule,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";

export type EmployeeItem = ISheetMonthSchedule | IEmployee | null;

export type EmployeesQueryProperties = {
  tab: "Éléments de paie" | "Salariés" | null;
  search: string | undefined;
  page: number;
  limit: number;
  year?: number;
  idMonth?: number;
};

export const defaultEmployeesQueryProperties = ref<EmployeesQueryProperties>({
  tab: null,
  search: undefined,
  page: 1,
  limit: 25,
});

export const useEmployeesStore = defineStore("employees", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultEmployeesQueryProperties.value) as EmployeesQueryProperties,
      items: null as (EmployeeItem & Loadable)[] | null,
      employeesList: null as IEmployee[] | null,
      maxPages: null as number | null,
      currentPage: null as number | null,
      isLoading: false as boolean,
      hasAnyItem: null as boolean | null,
      totalItems: null as number | null,
      dataForm: {
        mode: "new" as "new" | "edit" | "dupplicate",
        item: null as EmployeeItem | null,
      },
      workingSchedules: [] as UpdateOrCreateWorkingSchedule[],
      controllers: { items: null, hasItem: null } as {
        items: AbortController | null;
        hasItem: AbortController | null;
      },
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultEmployeesQueryProperties.value);
      this.items = [];
      this.employeesList = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
      this.dataForm = {
        mode: "new",
        item: null,
      };
      this.workingSchedules = [];
    },
    async fetchEmployeesItems() {
      const societyStore = useSocietyStore();
      this.isLoading = true;

      const { search, page, limit, tab } = this.queryProperties;

      const societyId = societyStore.society!.id!;
      const tabBeforeRequest = this.queryProperties.tab;

      if (this.controllers.items && this.controllers.items.signal) {
        await this.controllers.items.abort();
        this.controllers.items = null;
      }
      this.controllers.items = new AbortController();

      let response: any = null;
      if (tab === "Éléments de paie") {
        await $silex()
          .employee.getPaginate(societyId, { limit: 999 }, undefined, this.controllers.items.signal)
          .then(async (res) => {
            const idEmployee = res?.data.map((e: any) => e.id);
            this.employeesList = res.data ?? [];

            // Fetch SheetMonth
            const { year, idMonth } = this.queryProperties;
            const params = { idEmployee, search, page, limit, year, idMonth };
            response =
              idEmployee.length > 0
                ? await $silex().sheetMonthSchedule.getPaginate(params, undefined)
                : [];
          })
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      } else {
        await $silex()
          .employee.getPaginate(
            societyId,
            { search, page, limit },
            undefined,
            this.controllers.items.signal,
          )
          .then((res) => (response = res))
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      }
      this.isLoading = false;

      // if the tab has been changed before we have the response, don't use the response, because it can be for the wrong tab!
      // the response for the tab 1 can come after the response for the tab 2, when we go quick to tab 1 then to tab 2
      const tabAfterResponse = this.queryProperties.tab;
      if (
        tabAfterResponse !== tabBeforeRequest &&
        isDefined(tabAfterResponse) &&
        isDefined(tabBeforeRequest)
      ) {
        return;
      }

      this.maxPages = response?.maxPages ?? 0;
      this.currentPage = response?.currentPage ?? 0;
      this.totalItems = response?.totalItems ?? 0;

      const data = response?.data ?? [];
      this.items = data.map((eItem: EmployeeItem) => {
        return {
          ...eItem,
          isLoading: false,
        };
      });
    },
    async fetchHasAnyItem() {
      const societyStore = useSocietyStore();
      this.hasAnyItem = null;

      if (this.controllers.hasItem && this.controllers.hasItem.signal)
        this.controllers.hasItem.abort();
      this.controllers.hasItem = new AbortController();

      const tab = this.queryProperties.tab;
      const societyId = societyStore.society!.id!;
      const tabBeforeRequest = this.queryProperties.tab;

      let response: any = null;
      if (tab === "Éléments de paie") {
        const employeesList = await $silex().employee.getPaginate(
          societyId,
          {},
          undefined,
          this.controllers.hasItem.signal,
        );
        const idEmployee: number[] = employeesList.data?.map((e: any) => e.id) ?? [];

        const params = {
          idEmployee,
          page: 1,
          limit: 1,
        };
        response =
          idEmployee.length > 0
            ? await $silex().sheetMonthSchedule.getPaginate(params, undefined)
            : [];
      } else if (tab === "Salariés") {
        response = await $silex()
          .employee.getPaginate(
            societyId,
            {
              page: 1,
              limit: 1,
            },
            undefined,
            this.controllers.hasItem.signal,
          )
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          });
      }

      // if the tab has been changed before we have the response, don't use the response, because it can be for the wrong tab!
      // the response for the tab 1 can come after the response for the tab 2, when we go quick to tab 1 then to tab 2
      const tabAfterResponse = this.queryProperties.tab;

      if (
        tabAfterResponse !== tabBeforeRequest &&
        isDefined(tabAfterResponse) &&
        isDefined(tabBeforeRequest)
      ) {
        return;
      }

      const totalItems = response?.totalItems ?? 0;

      this.hasAnyItem = totalItems > 0;
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
