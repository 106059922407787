export const useCommonStore = defineStore("common", {
  state: () => {
    return {
      queryProperties: { startDate: null, endDate: null } as {
        startDate: string | null;
        endDate: string | null;
      },
      erroredItemIds: [] as number[],
      displayErrors: false as boolean,
      isSidePanelLoading: false as boolean,
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = { startDate: null, endDate: null };
      this.isSidePanelLoading = false;
      this.resetErrors();
    },
    resetErrors() {
      this.erroredItemIds = [];
      this.displayErrors = false;
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
