import type {
  CreateExerciceQuestions,
  IExerciceQuestion,
  ReadVatControlCuteOff,
  ReadRegistration,
  CustomerVatType,
  PayloadCerfa3310_2023,
} from "@silexpert/core";
import { Activity, FiscalRegime, GlobalRegime, ProfitsType, TaxSystem } from "@silexpert/core";

export type tempReadRegistration = ReadRegistration & {
  isSelected: boolean;
  amounts: {
    type: CustomerVatType | null;
    amount: number | null;
    collectable: number;
    deductible: number;
  };
  form?: PayloadCerfa3310_2023;
};

type VatControlState = {
  questions: IExerciceQuestion[];
  vatCollected: number | null;
  vatDeductible: number | null;
  needRetrocessionFees: boolean;
  vatControlQuestionsExercice: IExerciceQuestion[];
  dataForm: {
    declarations: tempReadRegistration[];
    cuteOffs: ReadVatControlCuteOff[];
  };
};

export const useVatControlStore = defineStore("vatControlStore", {
  state: () =>
    ref<VatControlState>({
      questions: [],
      vatCollected: null,
      vatDeductible: null,
      needRetrocessionFees: false,
      vatControlQuestionsExercice: [],
      dataForm: {
        declarations: [],
        cuteOffs: [],
      },
    }),
  actions: {
    reset() {
      this.questions = [];
      this.needRetrocessionFees = false;
      this.vatCollected = null;
      this.vatDeductible = null;
      this.vatControlQuestionsExercice = [];
      this.dataForm = {
        declarations: [],
        cuteOffs: [],
      };
    },
    async fetchQuestions(idExercice?: number) {
      try {
        const exerciceStore = useExerciceStore();
        this.questions = await $silex().vatControl.getExerciceQuestions(
          idExercice || exerciceStore.current!.id!,
        );
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    fetchNeedRetrocessionFees() {
      const societyStore = useSocietyStore();
      const society = societyStore.society;
      if (!society) {
        return;
      }
      const { idActivity, idLegalForm, idTaxSystem, profitsType, idFiscalRegime } = society;

      if (
        idLegalForm === GlobalRegime.ENTREPRISE_INDIVIDUELLE.id ||
        idLegalForm === GlobalRegime.EIRL.id ||
        idLegalForm === GlobalRegime.SELARL.id
      ) {
        if (
          idActivity === Activity.PROF_LIB &&
          idTaxSystem === TaxSystem.IR &&
          profitsType === ProfitsType.BNC &&
          idFiscalRegime === FiscalRegime.NA
        ) {
          this.needRetrocessionFees = true;
        }
      } else if (idLegalForm === GlobalRegime.SCP.id) {
        if (
          idActivity === Activity.PRESTA_SERVICE &&
          idTaxSystem === TaxSystem.IR &&
          profitsType === ProfitsType.BNC &&
          idFiscalRegime === FiscalRegime.NA
        ) {
          this.needRetrocessionFees = true;
        }
      }
    },
    async fetchVatValues(payload: { startDate: string; endDate: string }) {
      const dayjs = useDayjs();
      try {
        const { startDate, endDate } = payload;
        const ongoingVat = await $silex().dashboard.getTurnoverProfitAndLossAndVats({
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
        });

        const vats = ongoingVat.vats ?? [];
        let collectible: number = 0;
        let deductible: number = 0;

        vats.vats.forEach((vat) => {
          collectible += vat.collectible;
          deductible += vat.deductible;
        });
        this.vatCollected = collectible;
        this.vatDeductible = deductible;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async sendVatControlAnswer(payload: { answers: CreateExerciceQuestions }) {
      try {
        const exerciceStore = useExerciceStore();
        const societyStore = useSocietyStore();
        const idSociety = societyStore.society?.id ?? 0;
        const idExercice = exerciceStore.current?.id ?? 0;
        const { answers } = payload;
        await $silex().vatControl.updateOrCreate(idExercice, answers);
        await $silex().balanceSheetModule.createBalanceSheetModule(idSociety, {
          idExercice,
          hasCheckedVAT: true,
        });
        await this.fetchQuestions();
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchVatControlQuestionForExercice(payload: { idExercice: number }) {
      await $silex()
        .vatControl.getExerciceQuestions(payload.idExercice)
        .then((res) => {
          this.vatControlQuestionsExercice = res;
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          this.vatControlQuestionsExercice = [];
        });
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
