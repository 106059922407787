import { defaultBrand, brands } from "./brands.js";
import type { Brand } from "~/@types/localTypes/utils.js";

/**
 * CE FICHIER NE DOIT PAS ÊTRE MODIFIE !
 * IL GERE LES BRANDS, MODIFICATIONS TRES RISQUEES !
 */

export const nodeEnv = "production_comptastart";

export const environment = process.env.NODE_ENV === "development" ? process.env.NODE_ENV : nodeEnv;
export const apiHost =
  process.env.NODE_ENV === "development" ? "http://localhost:3031/" : "https://api-next-legalstart.compta-clementine.fr/";

export function checkBrand(): Brand {
  // Check if API_HOST match with a brand
  const matchingBrand = brands.find((b) => apiHost && apiHost.includes(b.urlFragment));

  // Return default properties if matchingBrand is empty
  if (!matchingBrand || Object.keys(matchingBrand).length === 0) {
    return defaultBrand;
  } else {
    return matchingBrand;
  }
}
