import type {
  BasePaginate,
  IAccountingFile,
  IAccountingJournal,
  IUser,
  ReadAccrualRevision,
  ReadRevisionAccount,
} from "@silexpert/core";
import { ThirdPartyType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { RevisionThirdPartyItem } from "./thirdparty";
import type { Loadable } from "~/@types/localTypes/utils";
export const useRevisionCustomerStore = defineStore("revisionCustomer", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultRevisionTPQueryProperties),
      items: null as (RevisionThirdPartyItem & Loadable)[] | null,
      savedItems: null as SavedRevisionTpItem | null,
      checkedItems: [] as RevisionThirdPartyItem[],
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      isLoading: false as boolean,
      allThirdParties: [] as RevisionThirdPartyItem[],
      compareMode: "PrevPeriod" as "PrevPeriod" | "NextPeriod" | "PrevYear" | "NextYear",
      shouldDisplayBasement: false as boolean,
      selectedMergeAccountId: null as number | null,
      controllers: { items: null, hasItem: null } as {
        items: AbortController | null;
        hasItem: AbortController | null;
      },
      hasAnyItem: null,
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultRevisionTPQueryProperties);
      this.items = [];
      this.savedItems = null;
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.allThirdParties = [];
      this.compareMode = "PrevPeriod";
      this.shouldDisplayBasement = false;
      this.selectedMergeAccountId = null;
      this.checkedItems = [];
    },
    async fetchItems(startDate: string | null, endDate: string | null) {
      const idSociety = useSocietyStore().society!.id!;

      const { itemId, page, limit, orderBy, descending, isFilteringMode, filters } =
        this.queryProperties;

      let response: any = null;

      if (this.controllers.items && this.controllers.items.signal) {
        await this.controllers.items.abort();
        this.controllers.items = null;
      }
      this.controllers.items = new AbortController();

      if (isFilteringMode) {
        const params = {
          ...(isDefined(startDate) ? { startDate } : {}),
          ...(isDefined(endDate) ? { endDate } : {}),
          ...(isDefined(filters.designation) ? { designation: filters.designation } : {}),
          ...(isDefined(filters.idThirdParty) ? { idThirdParty: filters.idThirdParty } : {}),
          ...(isDefined(filters.minimumAmount) ? { minimumAmount: filters.minimumAmount } : {}),
          ...(isDefined(filters.maximumAmount) ? { maximumAmount: filters.maximumAmount } : {}),
          ...(isDefined(filters.type) ? { type: filters.type } : {}),
          ...(isDefined(filters.idsAccountingJournal) && filters.idsAccountingJournal?.length > 0
            ? { idsAccountingJournal: filters.idsAccountingJournal }
            : {}),
          ...(isDefined(filters.isRevised) ? { isRevised: filters.isRevised } : {}),
          orderBy: filters.orderBy,
          descending: filters.descending,
          page,
          limit,
          idSociety,
        };

        await $silex()
          .revision.getPaginateEntriesBy({
            ...params,
            // @ts-expect-error: wrong backend type ? should be a string
            startDate: params.startDate as Date,
            // @ts-expect-error: wrong backend type ? should be a string
            endDate: params.endDate as Date,
          })
          .then((res: BasePaginate<ReadRevisionAccount>) => {
            response = res;
          })
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      } else if (isDefined(itemId)) {
        const params = {
          startDate: startDate ? new Date(startDate) : null,
          endDate: endDate ? new Date(endDate) : null,
          page,
          limit,
          idSociety,
          orderBy: orderBy as "debit" | "credit" | "idAccount" | "date" | "designation" | undefined,
          descending,
          idThirdPartyType: ThirdPartyType.CUSTOMER,
        };
        await $silex()
          .revision.getPaginateTransactionsByThridParty(
            itemId,
            {
              ...params,
              startDate: params.startDate as Date,
              endDate: params.endDate as Date,
            },
            undefined,
            this.controllers.items.signal,
          )
          .then((res: BasePaginate<ReadRevisionAccount>) => {
            response = res;
          })
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      } else {
        const params = {
          startDate: startDate ? new Date(startDate) : null,
          endDate: endDate ? new Date(endDate) : null,
          page,
          limit,
          idSociety,
          orderBy: orderBy as "number" | "designation" | "amount" | undefined,
          descending,
          idThirdPartyType: ThirdPartyType.CUSTOMER,
        };
        await $silex()
          .revision.getPaginatedByThirdParty(
            {
              ...params,
              startDate: params.startDate as Date,
              endDate: params.endDate as Date,
            },
            this.controllers.items.signal,
          )
          .then((res: BasePaginate<ReadAccrualRevision>) => {
            response = res;
            response.data = response.data.map((item: ReadAccrualRevision) => {
              return {
                ...item,
                id: item.thirdParty.id,
              };
            });
          })
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      }

      return response;
    },
    async fetchAllItems() {
      this.isLoading = true;

      const { startDate, endDate, startDate2, endDate2, itemId, isFilteringMode } =
        this.queryProperties;

      const response1 = await this.fetchItems(startDate, endDate);
      const data = response1?.data ?? response1 ?? [];

      let response2: any = null;
      let data2: any = null;
      let revisionItems: any = null;
      if (isDefined(startDate2) && isDefined(endDate2) && !isDefined(itemId) && !isFilteringMode) {
        response2 = await this.fetchItems(startDate2, endDate2);
        data2 = response2?.data ?? response1 ?? [];

        revisionItems = data?.map((item: ReadAccrualRevision) => {
          return {
            ...item,
            isLoading: false,
            comparedBalance: data2.find(
              (acc2: ReadAccrualRevision) => acc2.thirdParty?.id === item.thirdParty?.id,
            )?.balance,
          };
        });
      } else {
        revisionItems = data?.map((item: RevisionItem) => {
          return {
            ...item,
            isLoading: false,
          };
        });
      }

      this.isLoading = false;

      this.items = revisionItems;

      this.maxPages = response1?.maxPages ?? 0;

      this.currentPage = response1?.currentPage ?? 0;

      this.totalItems = response1?.totalItems ?? 0;
    },
    async fetchAllThirdParties() {
      const idSociety = useSocietyStore().society!.id!;
      const { isFilteringMode } = this.queryProperties;

      if (isFilteringMode) {
        await $silex()
          .revision.getPaginateEntriesBy({
            orderBy: "date",
            descending: true,
            page: 1,
            limit: 1000,
            idSociety,
            startDate: new Date("1970/01/01"),
            endDate: new Date("2170/01/01"),
          })
          .then((res: BasePaginate<ReadRevisionAccount>) => {
            this.allThirdParties = res.data;
          })
          .catch((error: any) => {
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            this.allThirdParties = [];
          });
      } else {
        await $silex()
          .revision.getPaginatedByThirdParty({
            page: 1,
            limit: 1000,
            idSociety,
            startDate: new Date("1970/01/01"),
            endDate: new Date("2170/01/01"),
            idThirdPartyType: ThirdPartyType.CUSTOMER,
          })
          .then((res: BasePaginate<ReadAccrualRevision>) => {
            this.allThirdParties = res.data.map((item: ReadAccrualRevision) => {
              return {
                ...item,
                id: item.thirdParty.id,
              };
            });
          })
          .catch((error: any) => {
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            this.allThirdParties = [];
          });
      }
    },
    setAccountingTransaction(payload: {
      itemId: number;
      property: keyof {
        id: number;
        idBankAccount: number;
        dateLocked: Date | null;
        dateVerified: Date | null;
        idUser: number | null;
        exported: Date | null;
        idAccountingJournal: number;
        accountingFiles: Array<IAccountingFile>;
        accountingJournal: IAccountingJournal;
        user: IUser;
      };
      value: any;
    }) {
      // update accountingTransaction data (level 2: ReadRevisionAccount.accountingTransaction)
      const { itemId, property, value } = payload;
      const revisionItems = (this.items as ReadRevisionAccount[]) ?? [];
      const revisionItemIndexToChange = revisionItems.findIndex(
        (rI: ReadRevisionAccount) => rI.id === itemId,
      );

      if (revisionItemIndexToChange > -1 && isDefined(this.items)) {
        (this.items[revisionItemIndexToChange] as ReadRevisionAccount).accountingTransaction[
          property
        ] = value;
        // Vue.set(revisionItems[revisionItemIndexToChange].accountingTransaction, property, value);
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
