import type {
  QueryTransactionsForComparisonPaginated,
  ReadBankAccountTransactionsIntermediateSolde,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type DiffByBankAccount = {
  idBankAccount: number;
  diffIsZero: boolean;
};

export type PreComptaQueryProperties = {
  startDate: string | null;
  endDate: string | null;
  idBankAccount: number | null;
  search: string | null;
  page: number;
  limit: number;
  orderBy: "amount" | "date" | undefined;
  descending: boolean;
  minimumAmount: number | null;
  maximumAmount: number | null;
  type: number | null;
};

export const defaultPreComptaQueryProperties = ref<PreComptaQueryProperties>({
  startDate: null,
  endDate: null,
  idBankAccount: null,
  search: null,
  page: 1,
  limit: 25,
  orderBy: "date",
  descending: true,
  minimumAmount: null,
  maximumAmount: null,
  type: null,
});

export const usePreComptaStore = defineStore("preCompta", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultPreComptaQueryProperties.value),
      items: [] as ReadBankAccountTransactionsIntermediateSolde[],
      checkedItems: [] as ReadBankAccountTransactionsIntermediateSolde[],
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      isLoading: false as boolean,
      hasAnyItem: null as boolean | null,
      diffByBankAccount: [] as DiffByBankAccount[],
      controllers: { items: null, diffByBankAccount: null } as {
        items: AbortController | null;
        diffByBankAccount: AbortController | null;
      },
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultPreComptaQueryProperties.value);
      this.items = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
      this.diffByBankAccount = [];
    },
    async fetchPreComptaItems(idBankAccountParam?: number) {
      this.isLoading = true;
      this.hasAnyItem = null;
      const societyStore = useSocietyStore();
      const exerciceStore = useExerciceStore();
      const dayjs = useDayjs();
      const idSociety = societyStore.society?.id;
      const {
        startDate,
        endDate,
        limit,
        page,
        idBankAccount,
        orderBy,
        descending,
        search,
        maximumAmount,
        minimumAmount,
        type,
      } = this.queryProperties;
      const params: QueryTransactionsForComparisonPaginated = {
        idSociety: idSociety!,
        startDate:
          startDate ??
          dayjs(exerciceStore.defaultExercice.startDate ?? "1970-01-01").format("YYYY-MM-DD"),
        endDate:
          endDate ??
          dayjs(exerciceStore.defaultExercice.endDate ?? "1970-01-01").format("YYYY-MM-DD"),
        descending,
        order: orderBy,
        ...(isDefined(limit) ? { limit } : {}),
        ...(isDefined(page) ? { page } : {}),
        ...(isDefined(search) ? { search } : {}),
        ...(isDefined(minimumAmount) ? { minimumAmount } : {}),
        ...(isDefined(maximumAmount) ? { maximumAmount } : {}),
        ...(isDefined(type) ? { filterByType: type } : {}),
      };

      if (this.controllers.items && this.controllers.items.signal) {
        await this.controllers.items.abort();
        this.controllers.items = null;
      }
      await $silex()
        .bankAccount.getPaginatedTransactionsWithIntermediateBalance(
          idBankAccount ?? idBankAccountParam ?? 0,
          params,
          this.controllers.items?.signal,
        )
        .then((items) => {
          this.isLoading = false;
          this.items = items.data;
          this.maxPages = items.maxPages;
          this.currentPage = items.currentPage;
          this.totalItems = items.totalItems;
          this.hasAnyItem = items.totalItems > 0;
        })
        .catch((error) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
    async fetchIsDiffEqual0(idBankAccount: number) {
      const societyStore = useSocietyStore();
      const exerciceStore = useExerciceStore();
      const dayjs = useDayjs();
      const idSociety = societyStore.society?.id;
      const {
        startDate,
        endDate,
        limit,
        page,
        orderBy,
        descending,
        minimumAmount,
        maximumAmount,
        type,
      } = this.queryProperties;

      const params: QueryTransactionsForComparisonPaginated = {
        idSociety: idSociety!,
        startDate:
          startDate ??
          dayjs(exerciceStore.defaultExercice.startDate ?? "1970-01-01").format("YYYY-MM-DD"),
        endDate:
          endDate ??
          dayjs(exerciceStore.defaultExercice.endDate ?? "1970-01-01").format("YYYY-MM-DD"),
        descending,
        order: orderBy,
        ...(isDefined(limit) ? { limit } : {}),
        ...(isDefined(page) ? { page } : {}),
        ...(isDefined(minimumAmount) ? { minimumAmount } : {}),
        ...(isDefined(maximumAmount) ? { maximumAmount } : {}),
        ...(isDefined(type) ? { filterByType: type } : {}),
      };

      if (this.controllers.diffByBankAccount && this.controllers.diffByBankAccount.signal) {
        await this.controllers.diffByBankAccount.abort();
        this.controllers.diffByBankAccount = null;
      }
      await $silex()
        .bankAccount.getHasDifferenceForLastSevenDays(
          idBankAccount!,
          params,
          this.controllers.diffByBankAccount?.signal,
        )
        .then((hasNotDiff7days) => {
          const exisitingId = this.diffByBankAccount.find(
            (bk) => bk.idBankAccount === idBankAccount,
          );
          if (exisitingId) {
            const newDiff = this.diffByBankAccount.map((ba) => ({
              ...ba,
              ...(ba.idBankAccount === idBankAccount ? { diffIsZero: hasNotDiff7days } : {}),
            }));
            this.diffByBankAccount = newDiff;
          } else {
            const newDiff = this.diffByBankAccount.concat([
              { idBankAccount, diffIsZero: hasNotDiff7days },
            ]);
            this.diffByBankAccount = newDiff;
          }
        })
        .catch((error) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
