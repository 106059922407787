import type { IAppNotification } from "@silexpert/core";

type NotificationState = {
  appNotifications: IAppNotification[];
};

export const useNotificationStore = defineStore("notification", {
  state: () =>
    ref<NotificationState>({
      appNotifications: [],
    }),
  actions: {
    reset() {
      this.appNotifications = [];
    },
    async fetchSocietyAppNotifications(idSociety: number) {
      const dayjs = useDayjs();
      try {
        const notifications = await $silex().notification.getAllBySociety(idSociety);
        this.appNotifications = notifications.sort((a, b) => {
          return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1;
        });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async markAsRead(markAsRead: boolean, idNotifications?: number[]) {
      try {
        await $silex().notification.updateStatus({
          idNotifications: idNotifications ?? null,
          markAsRead,
        });

        if (idNotifications && idNotifications.length > 0) {
          this.appNotifications = this.appNotifications.map((notification) => {
            if (idNotifications.includes(notification.id)) {
              notification.read = markAsRead;
            }

            return notification;
          });
        } else {
          this.appNotifications = this.appNotifications.map((notification) => {
            notification.read = markAsRead;
            return notification;
          });
        }
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
