import type {
  AccountToMap,
  CreateFecFile,
  IFecFile,
  JournalToMap,
  ReadFecFile,
} from "@silexpert/core";
import { FecFileDelimiter } from "@silexpert/core";

type FecState = {
  fecFile: IFecFile | null;
  creation: ReadFecFile | null;
  journalsToMap: JournalToMap[];
  accountsToMap: AccountToMap[];
};

export const useFecStore = defineStore("fecStore", {
  state: () =>
    ref<FecState>({
      fecFile: null,
      creation: null,
      journalsToMap: [],
      accountsToMap: [],
    }),
  actions: {
    reset() {
      this.fecFile = null;
      this.creation = null;
      this.journalsToMap = [];
      this.accountsToMap = [];
    },
    async fetch(): Promise<void> {
      try {
        const societyStore = useSocietyStore();
        this.fecFile = await $silex().fecFile.getBySociety(societyStore.society!.id!);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async create(payload: { delimiter: FecFileDelimiter; file: File }): Promise<void> {
      try {
        const societyStore = useSocietyStore();

        const formData = new FormData();
        const { file, delimiter } = payload;

        formData.append("file", file);
        // @ts-expect-error
        formData.append("delimiter", delimiter === "\\t" ? FecFileDelimiter.TAB : delimiter);
        this.creation = await $silex().fecFile.createBySociety(
          societyStore.society!.id!,
          formData as unknown as CreateFecFile,
        );
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async mapping() {
      try {
        const societyStore = useSocietyStore();
        await $silex().fecFile.mapBySociety(societyStore.society!.id!, {
          mapping: {
            accountsToMap: this.accountsToMap,
            journalsToMap: this.journalsToMap,
          },
        });
        this.journalsToMap = [];
        this.accountsToMap = [];
        this.creation = null;
        $notifier().open({ type: "success", content: "Le FEC a bien été importé" });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
