import type { ReadFreelanceTurnover } from "@silexpert/core";

type SelfEmployedState = {
  isLoading: boolean;
  turnover: ReadFreelanceTurnover | null;
};

export const useSelfEmployedStore = defineStore("selfEmployedStore", {
  state: () =>
    ref<SelfEmployedState>({
      isLoading: false,
      turnover: null,
    }),
  actions: {
    reset() {
      this.isLoading = false;
      this.turnover = null;
    },
    async fetch(idExercice?: number) {
      try {
        this.isLoading = true;
        const exerciceStore = useExerciceStore();
        this.turnover = await $silex().balanceSheet.getFreelanceTurnover({
          idExercice: idExercice || exerciceStore.current!.id!,
        });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
