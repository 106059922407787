import type { IAccountingFile, ReadFormattedAccountingTransaction } from "@silexpert/core";
import { AccountingFileType, AccountingJournal } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";
export type PurchasesItem =
  | (IAccountingFile & Loadable)
  | (ReadFormattedAccountingTransaction & Loadable);

export type PurchasesQueryProperties = {
  tab: "invoices" | "expensenotes" | null;
  itemId: number | null;
  startDate: string | null;
  endDate: string | null;
  search: string | null;
  page: number;
  limit: number;
  orderBy: string;
  descending: boolean;
  toAnnotate: boolean;
  fromAnnotateGL: boolean;
  checkedIds: number[];
};

export const defaultPurchasesQueryProperties = ref<PurchasesQueryProperties>({
  tab: null,
  itemId: null,
  startDate: null,
  endDate: null,
  search: null,
  page: 1,
  limit: 25,
  orderBy: "dateValue",
  descending: true,
  toAnnotate: false,
  fromAnnotateGL: false,
  checkedIds: [],
});

export const usePurchasesStore = defineStore("purchases", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultPurchasesQueryProperties.value) as PurchasesQueryProperties,
      items: null as PurchasesItem[] | null,
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      isLoading: false as boolean,
      hasAnyItem: null as boolean | null,
      totalAmount: null as number | null,
      controllers: { invoices: null, hasItem: null } as {
        invoices: AbortController | null;
        hasItem: AbortController | null;
      },
    };
  },
  getters: {},
  actions: {
    reset() {
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.queryProperties = cloneDeep(defaultPurchasesQueryProperties.value);
      this.items = [];
    },
    async fetchTransactions(idSociety: number) {
      const societyStore = useSocietyStore();
      const isAccruals = societyStore.isAccruals;

      this.isLoading = true;
      this.items = [];

      const tabBeforeRequest = this.queryProperties.tab;

      if (isAccruals) {
        const { startDate, endDate, search, page, limit, orderBy, descending, toAnnotate, tab } =
          this.queryProperties;
        try {
          const response = await $silex().transaction.getFormattedPaginate(idSociety, {
            id: idSociety,
            idAccountingJournal: [
              ...(tab === "invoices" ? [AccountingJournal.AC] : []),
              ...(tab === "expensenotes" ? [AccountingJournal.PER] : []),
            ],
            ...(startDate ? { startDate } : {}),
            ...(endDate ? { endDate } : {}),
            ...(search ? { search } : {}),
            page,
            limit,
            ...(orderBy ? { orderBy } : {}),
            descending,
            toAnnotate,
          });

          const tabAfterResponse = this.queryProperties.tab;

          if (tabAfterResponse !== tabBeforeRequest) {
            return;
          }

          const { data, maxPages, currentPage, totalItems } = response;

          this.isLoading = false;
          const transactions = data.map((item: ReadFormattedAccountingTransaction) => {
            return {
              ...item,
              isLoading: false,
            };
          });
          this.items = transactions;
          this.maxPages = maxPages;
          this.currentPage = currentPage;
          this.totalItems = totalItems;
        } catch (error) {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        }
      } else {
        const { startDate, endDate, search, page, limit, orderBy, descending } =
          this.queryProperties;
        try {
          //
          const response = await $silex().accountingFiles.getPaginated(idSociety, {
            idSociety,
            ...(startDate ? { startDate } : {}),
            ...(endDate ? { endDate } : {}),
            ...(search ? { search } : {}),
            // @ts-expect-error
            idTypeAccountingFile: AccountingFileType.PURCHASE_INVOICES,
            page,
            limit,
            ...(orderBy ? { orderBy } : {}),
            descending,
          });

          const tabAfterResponse = this.queryProperties.tab;

          if (tabAfterResponse !== tabBeforeRequest) {
            return;
          }

          const { data, maxPages, currentPage, totalItems } = response;

          this.isLoading = false;
          const transactions = data.map((item: IAccountingFile) => {
            return {
              ...item,
              isLoading: false,
            };
          });
          this.items = transactions;
          this.maxPages = maxPages;
          this.currentPage = currentPage;
          this.totalItems = totalItems;
        } catch (error) {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        }
      }
    },
    async fetchHasAnyItem(idSociety: number) {
      const societyStore = useSocietyStore();
      const isAccruals = societyStore.isAccruals;
      this.isLoading = true;

      const tabBeforeRequest = this.queryProperties.tab;

      if (isAccruals) {
        const { tab } = this.queryProperties;
        try {
          const response = await $silex().transaction.getFormattedPaginate(idSociety, {
            id: idSociety,
            page: 1,
            limit: 1,
            idAccountingJournal: [
              ...(tab === "invoices" ? [AccountingJournal.AC] : []),
              ...(tab === "expensenotes" ? [AccountingJournal.PER] : []),
            ],
          });

          const tabAfterResponse = this.queryProperties.tab;

          if (tabAfterResponse !== tabBeforeRequest) {
            return;
          }

          const totalItems = response?.totalItems ?? 0;
          const hasAnyItem = totalItems > 0;

          this.hasAnyItem = hasAnyItem;
        } catch (error) {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        }
      } else {
        try {
          //
          const response = await $silex().accountingFiles.getPaginated(idSociety, {
            id: idSociety,
            page: 1,
            limit: 1,
            // @ts-expect-error
            idTypeAccountingFile: AccountingFileType.PURCHASE_INVOICES,
          });

          const tabAfterResponse = this.queryProperties.tab;

          if (tabAfterResponse !== tabBeforeRequest) {
            return;
          }

          const totalItems = response?.totalItems ?? 0;
          const hasAnyItem = totalItems > 0;

          this.hasAnyItem = hasAnyItem;
        } catch (error) {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        }
      }
    },
    setItemIsLoading(itemId: number, isLoading: boolean) {
      const items = this.items ?? [];
      const indexToChange = items.findIndex((a) => a.id === itemId);

      if (indexToChange > -1 && isDefined(this.items)) {
        this.items[indexToChange].isLoading = isLoading;
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
