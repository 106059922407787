import type { ReadShareCapital } from "@silexpert/core";

export type KindContributions = {
  amount: number;
  designation: string | null;
  idAccount: number | null;
};

type SocialCapitalState = {
  isLoading: boolean;
  data: ReadShareCapital | null;
};

export const useSocialCapitalStore = defineStore("socialCapital", {
  state: () =>
    ref<SocialCapitalState>({
      isLoading: false,
      data: null,
    }),
  actions: {
    reset() {
      this.isLoading = false;
      this.data = null;
    },
    async fetch(idExercice?: number) {
      try {
        this.isLoading = true;
        const exerciceStore = useExerciceStore();
        this.data = await $silex().shareCapital.get(idExercice || exerciceStore.current!.id!);
        this.isLoading = false;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
