import type { Role } from "@silexpert/core";
import { ReconciliationType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";

export type BalanceSheetDocumentsQueryProperties = {
  search: string | null;
  itemId: number | null;
  limit: number;
  page: number;
};

export const defaultBalanceSheetDocumentsQueryProperties =
  ref<BalanceSheetDocumentsQueryProperties>({
    search: null,
    itemId: null,
    limit: 0,
    page: 1,
  });

export enum TypeBalanceSheetDocuments {
  INTERMEDIATE_MANAGEMENT_REBATE = 1,
  GENERAL_LEDGER = 2,
  INCOME_STATEMENT = 3,
  BALANCE_SHEET = 4,
  JOURNAL = 5,
  BALANCE = 6,
  IMMOBILIZATION = 7,
  FEC = 8,
  IMMOBILIZATION_RECONCILIATION = 9,
  AMORTIZATION_RECONCILIATION = 10,
  PROJECT = 11,
}

export enum TypeBalanceSheetDocumentsAction {
  GENERATE = 1,
  DOWNLOAD = 2,
}

export type DocumentItem = {
  id: TypeBalanceSheetDocuments;
  label: string;
  actionLabel: string;
  fileType: string;
  actionType: TypeBalanceSheetDocumentsAction;
  roles?: Array<Role>;
  action: () => Promise<Buffer | undefined>;
};

export const balanceSheetDocumentsList: DocumentItem[] = [
  {
    id: TypeBalanceSheetDocuments.INTERMEDIATE_MANAGEMENT_REBATE,
    label: "Soldes Intermédiaires de Gestion",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () => useBalanceSheetDocumentsStore().downloadIntermediateManagementRebate(),
  },
  {
    id: TypeBalanceSheetDocuments.GENERAL_LEDGER,
    label: "Grand Livre Général",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () => useBalanceSheetDocumentsStore().downloadGeneralLedger(),
  },
  {
    id: TypeBalanceSheetDocuments.INCOME_STATEMENT,
    label: "Compte de résultat",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () => useBalanceSheetDocumentsStore().downloadIncomeStatement(),
  },
  {
    id: TypeBalanceSheetDocuments.BALANCE_SHEET,
    label: "Bilan",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () => useBalanceSheetDocumentsStore().downloadBalancesheet(),
  },
  {
    id: TypeBalanceSheetDocuments.JOURNAL,
    label: "Journaux",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () => useBalanceSheetDocumentsStore().downloadJournal(),
  },
  {
    id: TypeBalanceSheetDocuments.BALANCE,
    label: "Balance",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () => useBalanceSheetDocumentsStore().downloadBalance(),
  },
  {
    id: TypeBalanceSheetDocuments.IMMOBILIZATION,
    label: "Registre des immobilisations",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () => useBalanceSheetDocumentsStore().downloadImmobilization(),
  },
  {
    id: TypeBalanceSheetDocuments.IMMOBILIZATION_RECONCILIATION,
    label: "Rapprochement des immobilisations",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () =>
      useBalanceSheetDocumentsStore().generateReconciliationFile(ReconciliationType.IMMOBILIZATION),
  },
  {
    id: TypeBalanceSheetDocuments.AMORTIZATION_RECONCILIATION,
    label: "Rapprochement des amortissements",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () =>
      useBalanceSheetDocumentsStore().generateReconciliationFile(ReconciliationType.AMORTIZATION),
  },
  {
    id: TypeBalanceSheetDocuments.PROJECT,
    label: "Plaquette",
    actionLabel: "Générer",
    actionType: TypeBalanceSheetDocumentsAction.GENERATE,
    fileType: "application/pdf",
    action: () => useBalanceSheetDocumentsStore().downloadProject(),
  },
  {
    id: TypeBalanceSheetDocuments.FEC,
    label: "Fichier des écritures comptables (FEC)",
    actionLabel: "Télécharger",
    actionType: TypeBalanceSheetDocumentsAction.DOWNLOAD,
    fileType: "text/plain",
    action: () => useBalanceSheetDocumentsStore().downloadFEC(),
  },
];

export const useBalanceSheetDocumentsStore = defineStore("balanceSheetDocuments", {
  state: () => {
    return {
      queryProperties: cloneDeep(
        defaultBalanceSheetDocumentsQueryProperties.value,
      ) as BalanceSheetDocumentsQueryProperties,
    };
  },
  getters: {
    filteredDocuments: (state) => {
      const roleComposable = useRolesComposable();

      return balanceSheetDocumentsList.filter((document: DocumentItem) => {
        if (document.roles) return roleComposable.hasOneOfTheseRoles(document.roles);

        if (state.queryProperties.search)
          return document.label.toLowerCase().includes(state.queryProperties.search.toLowerCase());

        return true;
      });
    },
  },
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultBalanceSheetDocumentsQueryProperties.value);
    },
    async downloadImmobilization(): Promise<Buffer | undefined> {
      const exerciceStore = useExerciceStore();

      try {
        return await $silex().immobilization.getImmobilizationsInventoryPdf({
          idExercice: exerciceStore.current!.id!,
        });
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    generateReconciliationFile(reconciliationType: ReconciliationType): Promise<Buffer> {
      const exerciceStore = useExerciceStore();
      const societyStore = useSocietyStore();
      const idSociety = societyStore?.society?.id ?? 0;

      const promise = new Promise<Buffer>((resolve, reject) => {
        $silex()
          .immobilization.getReconciliationFile(idSociety, {
            idExercice: exerciceStore.current!.id!,
            reconciliationType,
          })
          .then((response: Buffer) => {
            resolve(response);
          })
          .catch((error) => {
            $notifier().open({ content: apiErrorToString(error) });
            reject(error);
          });
      });
      return promise;
    },
    async downloadFEC(): Promise<Buffer | undefined> {
      const exerciceStore = useExerciceStore();

      try {
        return await $silex().fecFile.export(exerciceStore.current!.id!);
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async downloadJournal(): Promise<Buffer | undefined> {
      const exerciceStore = useExerciceStore();
      const dayjs = useDayjs();

      try {
        const buffer = await $silex().journal.export(exerciceStore.current!.idSociety!, "pdf", {
          startDate: dayjs(exerciceStore.current!.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(exerciceStore.current!.endDate).format("YYYY-MM-DD"),
        });

        return buffer as Buffer;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async downloadBalancesheet() {
      const exerciceStore = useExerciceStore();

      try {
        const buffer = await $silex().balanceSheet.getPdf({
          idExercice: exerciceStore.current!.id!,
        });
        return buffer;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async downloadBalance() {
      const exerciceStore = useExerciceStore();
      const dayjs = useDayjs();

      try {
        const queries = {
          dateStart: dayjs(exerciceStore.current!.startDate).toISOString(),
          dateEnd: dayjs(exerciceStore.current!.endDate).toISOString(),
          page: 1,
          limit: 1,
        };

        const buffer = await $silex().generalLedger.exportBalancePdf(
          exerciceStore.current!.idSociety!,
          queries,
        );

        return buffer;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async downloadIncomeStatement() {
      const exerciceStore = useExerciceStore();
      const dayjs = useDayjs();

      try {
        const buffer = await $silex().incomeStatement.exportPdf(exerciceStore.current!.idSociety!, {
          startDate: dayjs(exerciceStore.current!.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(exerciceStore.current!.endDate).format("YYYY-MM-DD"),
        });

        return buffer;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async downloadGeneralLedger() {
      const exerciceStore = useExerciceStore();
      const dayjs = useDayjs();

      try {
        const queries = {
          dateStart: dayjs(exerciceStore.current!.startDate).toISOString(),
          dateEnd: dayjs(exerciceStore.current!.endDate).toISOString(),
          page: 1,
          limit: 1,
        };

        const buffer = await $silex().generalLedger.getPdf(
          exerciceStore.current!.idSociety!,
          queries,
        );

        return buffer;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async downloadIntermediateManagementRebate() {
      const exerciceStore = useExerciceStore();

      try {
        const buffer = await $silex().intermediateManagementRebate.getFile(
          exerciceStore.current!.id!,
        );

        return buffer;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async downloadProject() {
      try {
        const exerciceStore = useExerciceStore();
        const buffer = await $silex().project.create(exerciceStore.current!.id!);
        return buffer;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
  },
});
