// Commons store for admin/customers & admin/providers
import type { ReadAccrualRevision, ReadRevisionAccount } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type RevisionThirdPartyItem = (ReadAccrualRevision & { id: number }) | ReadRevisionAccount;

export type SavedRevisionTpItem = {
  maxPages: number | null;
  currentPage: number | null;
  data: RevisionThirdPartyItem[];
};

export type RevisionTPQueryProperties = {
  startDate: string | null;
  endDate: string | null;
  startDate2: string | null;
  endDate2: string | null;
  idExercice: number | null;
  search: string | null;
  page: number;
  limit: number;
  itemId: number | null;
  checkedIds: number[];
  orderBy: "designation" | "amount" | "debit" | "credit" | undefined;
  descending: boolean;
  isFilteringMode: boolean;
  cycle: number | null;
  filters: AdvancedFiltersRevision;
};

export const defaultRevisionTPQueryProperties: RevisionTPQueryProperties = {
  startDate: null,
  endDate: null,
  startDate2: null,
  endDate2: null,
  idExercice: null,
  search: null,
  page: 1,
  limit: 300,
  itemId: null,
  checkedIds: [],
  orderBy: "designation",
  descending: false,
  cycle: null,
  isFilteringMode: false,
  filters: cloneDeep(defaultRevisionFiltersProperties.value),
};

export const useRevisionTPStore = defineStore("revisionTP", {
  state: () => {
    return {};
  },
  getters: {},
  actions: {
    reset() {},
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
