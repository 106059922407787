import type { IVatDeclarationSocietyDetail, Rof } from "@silexpert/core";

export const useRegistrationStore = defineStore("registration", {
  state: () =>
    ref({
      details: null as IVatDeclarationSocietyDetail | null,
    }),
  actions: {
    reset() {
      this.details = null;
    },
    async fetchDetails() {
      const societyStore = useSocietyStore();
      await $silex()
        .registration.getSettingsFromSociety(societyStore.society!.id!)
        .then((res: IVatDeclarationSocietyDetail | null) => {
          this.details = res;
        })
        .catch((error) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          throw error;
        });
    },
    async updateSettings(payload: {
      address: string;
      zipCode: string;
      siret: string;
      city: string;
      name: string;
      rof: Rof | null;
      companyTaxRof: Rof | null;
      iban: string;
      bic: string;
      amountToPay: number;
      ogaName: string | null;
      ogaId: string | null;
      ogaAddressNumero: string | null;
      ogaAddressVoie: string | null;
      ogaAddressZipCode: string | null;
      ogaAddressCity: string | null;
      ogaAddressType: string | null;
      ogaReference: string | null;
      balanceSheetRof: string | null;
      hasOga: boolean;
    }) {
      const societyStore = useSocietyStore();
      // Society
      await $silex()
        .society.updateSociety(societyStore.society!.id!, {
          address: payload.address,
          zipCode: payload.zipCode,
          siret: payload.siret,
          city: payload.city,
          name: payload.name,
        })
        .then(() => {
          societyStore.fetchSociety(societyStore.society!.id!);
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          throw error;
        });

      // ROF / OGA / IBAN / BIC
      await $silex()
        .registration.updateOrCreateSettingsFromSociety(societyStore.society!.id!, {
          rof: payload.rof,
          companyTaxRof: payload.companyTaxRof,
          // @ts-expect-error à retirer après maj du core
          balanceSheetRof: payload.balanceSheetRof,
          ogaName: payload.ogaName,
          ogaId: payload.ogaId,
          ogaAddressNumero: payload.ogaAddressNumero,
          ogaAddressType: payload.ogaAddressType,
          ogaAddressVoie: payload.ogaAddressVoie,
          ogaAddressZipCode: payload.ogaAddressZipCode,
          ogaAddressCity: payload.ogaAddressCity,
          ogaReference: payload.ogaReference,
          iban: payload.iban,
          bic: payload.bic,
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          throw error;
        });
      this.fetchDetails();
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
