import type { IGedFileType, VatType } from "@silexpert/core";
import { Vat } from "@silexpert/core";

type ServerInfoState = {
  hasAlreadyFetched: boolean;
  vats: VatType[] | null;
  gedFileType: IGedFileType[] | null;
  isSidebarAdminOpen: boolean;
};

/**
 * @deprecated
 */
export const useServerInfoStore = defineStore("serverInfo", {
  state: () =>
    ref<ServerInfoState>({
      hasAlreadyFetched: false,
      vats: null,
      // legalFormList: [],
      gedFileType: null,
      isSidebarAdminOpen: true,
    }),
  getters: {
    getVats: (state) => state.vats,
  },
  actions: {
    async initializeServerInfo() {
      await Promise.all([this.fetchVats()]);
      this.hasAlreadyFetched = true;
    },
    fetchVats() {
      const vats = Object.values(Vat).sort((a, b) => a.displayOrder - b.displayOrder);
      this.vats = vats;
    },
  },

  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
