import type { IUser, IPermission, ISociety, IRecontactHistory } from "@silexpert/core";
import { RoleAllowedToLogInAsClient, Role } from "@silexpert/core";
import { EPermissions } from "~/composables/permissions";
import { isAuthenticated } from "~/middleware/auth.global";

type UserState = {
  user: IUser | null;
  currentSocietyId: number | null;
  permissions: EPermissions[];
  societies: ISociety[];
  contactRequests: IRecontactHistory[];
  accountants: IUser[];
};

export const useUsersStore = defineStore("users", {
  state: () =>
    ref<UserState>({
      user: null,
      currentSocietyId: null,
      permissions: [],
      societies: [],
      contactRequests: [],
      accountants: [],
    }),
  getters: {
    homePage: (state): string => {
      if (!isAuthenticated()) return "/login";
      const roleComposable = useRolesComposable();
      const accessComposable = useAccessComposable();
      const societyStore = useSocietyStore();

      if (
        !isDefined(societyStore.society) &&
        roleComposable.hasOneOfTheseRoles([Role.PARTNERED_ACCOUNTANT])
      ) {
        return "/expert/performances";
      }
      if (
        !isDefined(societyStore.society) &&
        roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient)
      ) {
        return "/admin/societies";
      }

      if (accessComposable.isRestricted()) {
        return "/sales";
      }

      if (
        state.permissions.includes(EPermissions.STATS) ||
        roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient)
      ) {
        return "/dashboard";
      }
      if (state.permissions.includes(EPermissions.COMPTA)) {
        return "/banks";
      }
      if (state.permissions.includes(EPermissions.GESCOM)) {
        return "/sales";
      }
      if (state.permissions.includes(EPermissions.DOC_TRESO)) {
        return "/library";
      }
      if (state.permissions.includes(EPermissions.TVA)) {
        return "/vat";
      }
      if (state.permissions.includes(EPermissions.BALANCESHEET)) {
        return "/balancesheet";
      }
      if (state.permissions.includes(EPermissions.SETTING)) {
        return "/settings";
      }
      return "/errors/notAllowed";
    },
    client: (state): IUser | null => {
      const roleComposable = useRolesComposable();
      const isClient = roleComposable.hasOneOfTheseRoles([Role.CLIENT]);
      const societyStore = useSocietyStore();

      return isClient ? state.user : societyStore.getOwner!;
    },
    isSupervisor: (): boolean => {
      const roleComposable = useRolesComposable();
      return roleComposable.hasOneOfTheseRoles([
        Role.EXPERT_ACCOUNTANT,
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.MANAGER,
      ]);
      return false;
    },
    isExpertAccountant: (): boolean => {
      const roleComposable = useRolesComposable();
      return roleComposable.hasOneOfTheseRoles([Role.EXPERT_ACCOUNTANT]);
    },
    isAccountant: (): boolean => {
      const roleComposable = useRolesComposable();
      return roleComposable.hasOneOfTheseRoles([Role.FRONT_OFFICE]);
    },
  },
  actions: {
    reset() {
      this.user = null;
      this.currentSocietyId = null;
      this.permissions = [];
      this.societies = [];
      this.contactRequests = [];
    },
    setUser(user: IUser, idSocietyByDefault?: number) {
      delete user.password;
      delete user.hash;

      this.user = user;
      this.currentSocietyId = idSocietyByDefault ?? user.societyByDefault ?? null;

      if ((user.userPermissions?.length ?? 0) > 0) {
        const permissionForDefaultSociety: IPermission | null =
          user.userPermissions?.filter(
            (userPerm) => userPerm.idSociety === this.currentSocietyId,
          )[0]?.permission ?? null;
        if (permissionForDefaultSociety) {
          this.permissions = Object.keys(permissionForDefaultSociety).filter(
            // @ts-expect-error
            (key: EPermissions) => permissionForDefaultSociety[key] === true,
          );
        }
      }
    },
    async fetchSocieties() {
      if (this.client?.id)
        await $silex()
          .user.getAllSocieties(this.client?.id as number, {})
          .then((res) => (this.societies = res))
          .catch((error) => $notifier().open({ type: "error", content: apiErrorToString(error) }));
    },
    async fetchContactHistory() {
      await $silex()
        .customerActivity.getAllContactRequests(this.user!.id!, { idSource: 100 })
        .then((res) => (this.contactRequests = res))
        .catch((error) => $notifier().open({ type: "error", content: apiErrorToString(error) }));
    },
    async fetchAccountants() {
      await $silex()
        .user.allCollaboratorsByRoles({
          idRoles: [Role.FRONT_OFFICE],
        })
        .then((list) => {
          const listAccount = list.map((ele) => ele.members)[0];
          this.accountants = listAccount.sort((a, b) =>
            a.fullname!.toLowerCase() > b.fullname!.toLowerCase()
              ? 1
              : a.fullname!.toLowerCase() < b.fullname!.toLowerCase()
                ? -1
                : 0,
          );
        })
        .catch((error) => $notifier().open({ type: "error", content: apiErrorToString(error) }));
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
