import type {
  BasePaginate,
  IAccountingFile,
  IAccountingJournal,
  IGedFile,
  IUser,
  QueryRevisionAccount,
  QueryRevisionPaginate,
  QueryRevisionSearchEntries,
  ReadRevision,
  ReadRevisionAccount,
} from "@silexpert/core";
import { GedFileType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";
export type RevisionItem = (ReadRevision & { id: number }) | ReadRevisionAccount;

export type SavedItem = {
  maxPages: number | null;
  currentPage: number | null;
  data: RevisionItem[];
};

export type AdvancedFiltersRevision = {
  designation: string | null;
  startAccountNumber?: string | null;
  endAccountNumber?: string | null;
  minimumAmount: number | null;
  maximumAmount: number | null;
  isRevised: boolean | null | undefined;
  type: number | null;
  idsAccountingJournal: number[];
  page: number;
  limit: number;
  orderBy:
    | "designation"
    | "amount"
    | "date"
    | "debit"
    | "credit"
    | "accountNumber"
    | "vatRate"
    | undefined;
  descending: boolean;
  idThirdParty?: number | null;
};

export type RevisionQueryProperties = {
  startDate: string | null;
  endDate: string | null;
  startDate2: string | null;
  endDate2: string | null;
  idExercice: number | null;
  search: string | null;
  page: number;
  limit: number;
  itemId: number | null;
  checkedIds: number[];
  orderBy: "number" | "designation" | "amount" | "debit" | "credit" | undefined;
  descending: boolean;
  cycle: number | null;
  filters: AdvancedFiltersRevision;
  isFilteringMode: boolean;
};

export const defaultRevisionFiltersProperties = ref<AdvancedFiltersRevision>({
  designation: null,
  startAccountNumber: null,
  endAccountNumber: null,
  minimumAmount: null,
  maximumAmount: null,
  isRevised: undefined,
  type: null,
  idsAccountingJournal: [],
  page: 1,
  limit: 25,
  orderBy: "accountNumber",
  descending: false,
});

export const defaultRevisionQueryProperties = ref<RevisionQueryProperties>({
  startDate: null,
  endDate: null,
  startDate2: null,
  endDate2: null,
  idExercice: null,
  search: null,
  page: 1,
  limit: 300,
  itemId: null,
  checkedIds: [],
  orderBy: "number",
  descending: false,
  cycle: null,
  filters: cloneDeep(defaultRevisionFiltersProperties.value),
  isFilteringMode: false,
});

export const useRevisionStore = defineStore("revision", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultRevisionQueryProperties.value),
      items: null as (RevisionItem & Loadable)[] | null,
      savedItems: null as SavedItem | null,
      checkedItems: [] as RevisionItem[],
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      isLoading: false as boolean,
      allAccounts: [] as RevisionItem[],
      compareMode: "PrevPeriod" as "PrevPeriod" | "NextPeriod" | "PrevYear" | "NextYear",
      shouldDisplayBasement: true as boolean,
      selectedMergeAccountId: null as number | null,
      balanceSheetNote: null as IGedFile | null,
      summaryNote: null as IGedFile | null,
      controllers: { items: null, hasItem: null } as {
        items: AbortController | null;
        hasItem: AbortController | null;
      },
      hasAnyItem: null,
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultRevisionQueryProperties.value);
      this.items = [];
      this.savedItems = null;
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.allAccounts = [];
      this.compareMode = "PrevPeriod";
      this.shouldDisplayBasement = true;
      this.selectedMergeAccountId = null;
      this.balanceSheetNote = null;
      this.summaryNote = null;
      this.checkedItems = [];
    },
    async fetchItems() {
      const idSociety = useSocietyStore().society!.id!;

      const {
        itemId,
        page,
        limit,
        orderBy,
        descending,
        isFilteringMode,
        filters,
        startDate,
        endDate,
        startDate2,
        endDate2,
      } = this.queryProperties;

      let response: any = null;

      if (this.controllers.items && this.controllers.items.signal) {
        await this.controllers.items.abort();
        this.controllers.items = null;
      }
      this.controllers.items = new AbortController();

      if (isFilteringMode) {
        const params: QueryRevisionSearchEntries = {
          startDate: new Date(startDate ?? "1970-01-01"),
          endDate: new Date(endDate ?? "2170-01-01"),
          ...(isDefined(startDate2) &&
          isDefined(endDate2) &&
          ["NextPeriod", "NextYear"].includes(this.compareMode)
            ? { nextStartDate: new Date(startDate2), nextEndDate: new Date(endDate2) }
            : {}),
          ...(isDefined(startDate2) &&
          isDefined(endDate2) &&
          ["PrevPeriod", "PrevYear"].includes(this.compareMode)
            ? { prevStartDate: new Date(startDate2), prevEndDate: new Date(endDate2) }
            : {}),
          ...(isDefined(filters.designation) ? { designation: filters.designation } : {}),
          ...(isDefined(filters.startAccountNumber)
            ? { startAccountNumber: filters.startAccountNumber }
            : {}),
          ...(isDefined(filters.endAccountNumber)
            ? { endAccountNumber: filters.endAccountNumber }
            : {}),
          ...(isDefined(filters.minimumAmount) ? { minimumAmount: filters.minimumAmount } : {}),
          ...(isDefined(filters.maximumAmount) ? { maximumAmount: filters.maximumAmount } : {}),
          ...(isDefined(filters.type) ? { type: filters.type } : {}),
          ...(isDefined(filters.idsAccountingJournal) && filters.idsAccountingJournal?.length > 0
            ? { idsAccountingJournal: filters.idsAccountingJournal }
            : {}),
          ...(isDefined(filters.isRevised) ? { isRevised: filters.isRevised } : {}),
          orderBy: filters.orderBy,
          descending: filters.descending,
          page,
          limit,
          idSociety,
        };

        await $silex()
          .revision.getPaginateEntriesBy(params)
          .then((res: BasePaginate<ReadRevisionAccount>) => {
            response = res;
          })
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      } else if (isDefined(itemId)) {
        const params: QueryRevisionAccount = {
          startDate: startDate ? new Date(startDate) : new Date("1970-01-01"),
          endDate: endDate ? new Date(endDate) : new Date("2170-01-01"),
          ...(isDefined(startDate2) &&
          isDefined(endDate2) &&
          ["NextPeriod", "NextYear"].includes(this.compareMode)
            ? { nextStartDate: new Date(startDate2), nextEndDate: new Date(endDate2) }
            : {}),
          ...(isDefined(startDate2) &&
          isDefined(endDate2) &&
          ["PrevPeriod", "PrevYear"].includes(this.compareMode)
            ? { prevStartDate: new Date(startDate2), prevEndDate: new Date(endDate2) }
            : {}),
          page,
          limit,
          idSociety,
          orderBy: orderBy as "debit" | "credit" | "idAccount" | "date" | "designation" | undefined,
          descending,
        };
        await $silex()
          .revision.getPaginateTransactionsByAccount(
            itemId,
            params,
            undefined,
            this.controllers.items.signal,
          )
          .then((res: BasePaginate<ReadRevisionAccount>) => {
            response = res;
          })
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      } else {
        const params: QueryRevisionPaginate = {
          startDate: startDate ? new Date(startDate) : new Date("1970-01-01"),
          endDate: endDate ? new Date(endDate) : new Date("2170-01-01"),
          ...(isDefined(startDate2) &&
          isDefined(endDate2) &&
          ["NextPeriod", "NextYear"].includes(this.compareMode)
            ? { nextStartDate: new Date(startDate2), nextEndDate: new Date(endDate2) }
            : {}),
          ...(isDefined(startDate2) &&
          isDefined(endDate2) &&
          ["PrevPeriod", "PrevYear"].includes(this.compareMode)
            ? { prevStartDate: new Date(startDate2), prevEndDate: new Date(endDate2) }
            : {}),
          page,
          limit,
          idSociety,
          orderBy: orderBy as "number" | "designation" | "amount" | undefined,
          descending,
        };
        await $silex()
          .revision.getPaginateByAccounts(params, this.controllers.items.signal)
          .then((res: BasePaginate<ReadRevision>) => {
            response = res;
            response.data = response.data.map((item: ReadRevision) => {
              return {
                ...item,
                id: item.account.id,
              };
            });
          })
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      }

      return response;
    },
    async fetchAllItems() {
      this.isLoading = true;

      const { itemId, isFilteringMode } = this.queryProperties;

      const response = await this.fetchItems();
      const data = response?.data ?? response ?? [];

      let revisionItems: any = null;
      if (!isDefined(itemId) && !isFilteringMode) {
        revisionItems = data?.map((item: ReadRevision) => {
          return {
            ...item,
            comparedBalance: ["NextPeriod", "NextYear"].includes(this.compareMode)
              ? item.nextBalance
              : item.previousBalance,
            isLoading: false,
          };
        });
      } else {
        revisionItems = data?.map((item: RevisionItem) => {
          return {
            ...item,
            isLoading: false,
          };
        });
      }

      this.isLoading = false;

      this.items = revisionItems;

      this.maxPages = response?.maxPages ?? 0;
      this.currentPage = response?.currentPage ?? 0;
      this.totalItems = response?.totalItems ?? 0;
    },
    async fetchAllAccounts() {
      const idSociety = useSocietyStore().society!.id!;
      let response: any = null;

      let tempAllAccounts: RevisionItem[] = [];

      const { startDate, endDate, startDate2, endDate2, isFilteringMode, filters } =
        this.queryProperties;
      const dayjs = useDayjs();

      const startDates = [
        ...(startDate ? [dayjs(startDate)] : []),
        ...(startDate2 ? [dayjs(startDate2)] : []),
      ];
      const endDates = [
        ...(endDate ? [dayjs(endDate)] : []),
        ...(endDate2 ? [dayjs(endDate2)] : []),
      ];
      const minDate = dayjs.min(startDates);
      const maxDate = dayjs.max(endDates);

      if (isFilteringMode) {
        await $silex()
          .revision.getPaginateEntriesBy({
            orderBy: filters.orderBy,
            descending: false,
            page: 1,
            limit: 3000,
            idSociety,
            startDate: minDate ? new Date(minDate.format()) : new Date("1970/01/01"),
            endDate: maxDate ? new Date(maxDate.format()) : new Date("2170/01/01"),
          })
          .then((res: BasePaginate<ReadRevisionAccount>) => {
            response = res.data;
          })
          .catch((error: any) => {
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      } else {
        await $silex()
          .revision.getPaginateByAccounts({
            page: 1,
            limit: 1000,
            idSociety,
            startDate: new Date("1970/01/01"),
            endDate: new Date("2170/01/01"),
          })
          .then((res: BasePaginate<ReadRevision>) => {
            response = res.data.map((item: ReadRevision) => {
              return {
                ...item,
                id: item.account.id,
              };
            });
          })
          .catch((error: any) => {
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      }

      // remove duplicates
      tempAllAccounts = (response as RevisionItem[]).filter(
        (acc, index, self) =>
          index === self.findIndex((account) => account.account!.id === acc.account!.id),
      );

      this.allAccounts = tempAllAccounts;
    },
    async fetchBalanceSheetNote() {
      const idSociety = useSocietyStore().society!.id!;
      const idExercice = this.queryProperties.idExercice;

      await $silex()
        .revision.getNote({
          idExercice: idExercice!,
          idSociety: idSociety!,
          gedFileType: GedFileType.BALANCESHEET_NOTE,
        })
        .then((res: IGedFile) => {
          this.balanceSheetNote = res;
        })
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
    async fetchSummaryNote() {
      const idSociety = useSocietyStore().society!.id!;
      const idExercice = this.queryProperties.idExercice;

      await $silex()
        .revision.getNote({
          idExercice: idExercice!,
          idSociety: idSociety!,
          gedFileType: GedFileType.SUMMARY_NOTE,
        })
        .then((res: IGedFile) => {
          this.summaryNote = res;
        })
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
    setAccountingTransaction(payload: {
      itemId: number;
      property: keyof {
        id: number;
        idBankAccount: number;
        dateLocked: Date | null;
        dateVerified: Date | null;
        idUser: number | null;
        exported: Date | null;
        idAccountingJournal: number;
        accountingFiles: Array<IAccountingFile>;
        accountingJournal: IAccountingJournal;
        user: IUser;
      };
      value: any;
    }) {
      // update accountingTransaction data (level 2: ReadRevisionAccount.accountingTransaction)
      const { itemId, property, value } = payload;
      const revisionItems = (this.items as ReadRevisionAccount[]) ?? [];
      const revisionItemIndexToChange = revisionItems.findIndex(
        (rI: ReadRevisionAccount) => rI.id === itemId,
      );

      if (revisionItemIndexToChange > -1 && isDefined(this.items)) {
        (this.items[revisionItemIndexToChange] as ReadRevisionAccount).accountingTransaction[
          property
        ] = value;
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
