import type {
  BasePaginate,
  EstimateStatus,
  IEstimateInvoice,
  ISocietyGescom,
  InvoiceStatus,
  ReadAccrualInvoice,
  ReadCashBasedInvoice,
  ReadCountDocumentByStatus,
  ReadEstimateInvoice,
  CreateCommercialSetting,
  CreateCgv,
  PaymentRecoveryStatus,
  IFile,
} from "@silexpert/core";
import { EstimateInvoiceType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";
export type FacturesItem = ReadCashBasedInvoice | ReadAccrualInvoice;
export type SalesItem = FacturesItem | IEstimateInvoice;

export type SalesQueryProperties = {
  tab: "Factures" | "Devis" | "Brouillons" | null;
  itemId: number | null;
  startDate: string | null;
  endDate: string | null;
  search: string | null;
  status: InvoiceStatus | EstimateStatus | null;
  page: number;
  limit: number;
  orderBy: null | "dateValue" | "createdAt" | "totalTaxIncluded";
  descending: boolean;
  toAnnotate: boolean;
  fromAnnotateGL: boolean;
  checkedIds: number[];
};

export const defaultSalesQueryProperties = ref<SalesQueryProperties>({
  tab: null,
  itemId: null,
  startDate: null,
  endDate: null,
  search: null,
  status: null,
  page: 1,
  limit: 25,
  orderBy: null,
  descending: true,
  toAnnotate: false,
  fromAnnotateGL: false,
  checkedIds: [],
});

export const useSalesStore = defineStore("sales", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultSalesQueryProperties.value) as SalesQueryProperties,
      items: null as (SalesItem & Loadable)[] | null,
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      isLoading: false as boolean,
      hasAnyItem: null as boolean | null,
      cgv: null as string | null,
      settings: null as ISocietyGescom | null,
      stats: null as ReadCountDocumentByStatus | null,
      totalAmount: null as number | null,
      controllers: { sales: null, hasItem: null } as {
        sales: AbortController | null;
        hasItem: AbortController | null;
      },
      dataForm: {
        mode: "new" as "new" | "edit" | "duplicate",
        itemId: null as number | null,
      },
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultSalesQueryProperties.value);
      this.items = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
      this.cgv = null;
      this.settings = null;
      this.stats = null;
      this.totalAmount = null;
      this.dataForm = {
        mode: "new",
        itemId: null,
      };
    },
    async fetchSalesItems() {
      const societyStore = useSocietyStore();
      this.isLoading = true;

      const {
        startDate,
        endDate,
        search,
        page,
        limit,
        descending,
        orderBy,
        status,
        tab,
        toAnnotate,
      } = this.queryProperties;

      const isAccruals = societyStore.isAccruals;
      const tabBeforeRequest = this.queryProperties.tab;

      if (this.controllers.sales && this.controllers.sales.signal) {
        await this.controllers.sales.abort();
        this.controllers.sales = null;
      }
      this.controllers.sales = new AbortController();

      let response: any = null;
      if (tab === "Factures") {
        if (isAccruals) {
          await $silex()
            .invoice.getAccrualPaginated(
              {
                ...(isDefined(startDate) ? { minDate: startDate } : {}),
                ...(isDefined(endDate) ? { maxDate: endDate } : {}),
                ...(isDefined(search) ? { search } : {}),
                ...(isDefined(status) ? { status: status as InvoiceStatus } : {}),
                ...(toAnnotate ? { toAnnotate } : {}),
                ...(isDefined(orderBy) ? { orderBy } : {}),
                page,
                limit,
                isDescending: descending,
              },
              undefined,
              this.controllers.sales.signal,
            )
            .then((res: BasePaginate<ReadAccrualInvoice>) => (response = res))
            .catch((error: any) => {
              if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
              $notifier().open({ type: "error", content: apiErrorToString(error) });
              response = [];
            });
        } else {
          await $silex()
            .invoice.getCashBasedPaginated(
              {
                ...(isDefined(startDate) ? { minDate: startDate } : {}),
                ...(isDefined(endDate) ? { maxDate: endDate } : {}),
                ...(isDefined(search) ? { search } : {}),
                ...(isDefined(status) ? { status: status as InvoiceStatus } : {}),
                ...(isDefined(orderBy) ? { orderBy } : {}),
                page,
                limit,
                isDescending: descending,
              },
              undefined,
              this.controllers.sales.signal,
            )
            .then((res: BasePaginate<ReadCashBasedInvoice>) => (response = res))
            .catch((error: any) => {
              if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
              $notifier().open({ type: "error", content: apiErrorToString(error) });
              response = [];
            });
        }
      } else if (tab === "Devis") {
        await $silex()
          .commercialDocument.getPaginated(
            {
              ...(isDefined(startDate) ? { minDate: startDate } : {}),
              ...(isDefined(endDate) ? { maxDate: endDate } : {}),
              ...(isDefined(search) ? { search } : {}),
              ...(isDefined(status) ? { status: status as EstimateStatus } : {}),
              ...(isDefined(orderBy) ? { orderBy } : {}),
              types: [EstimateInvoiceType.DEVIS, EstimateInvoiceType.BON],
              page,
              limit,
              isDescending: descending,
              isDraft: false,
            },
            undefined,
            this.controllers.sales.signal,
          )
          .then((res: BasePaginate<ReadEstimateInvoice>) => (response = res))
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      } else if (tab === "Brouillons") {
        await $silex()
          .commercialDocument.getPaginated(
            {
              ...(isDefined(startDate) ? { minDate: startDate } : {}),
              ...(isDefined(endDate) ? { maxDate: endDate } : {}),
              ...(isDefined(search) ? { search } : {}),
              ...(isDefined(status) ? { status: status as EstimateStatus } : {}),
              ...(isDefined(orderBy) ? { orderBy } : {}),
              types: [EstimateInvoiceType.FACTURE],
              page,
              limit,
              isDescending: descending,
              isDraft: true,
            },
            undefined,
            this.controllers.sales.signal,
          )
          .then((res: BasePaginate<ReadEstimateInvoice>) => (response = res))
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      }
      this.isLoading = false;

      // if the tab has been changed before we have the response, don't use the response, because it can be for the wrong tab!
      // the response for the tab 1 can come after the response for the tab 2, when we go quick to tab 1 then to tab 2
      const tabAfterResponse = this.queryProperties.tab;

      if (tabAfterResponse !== tabBeforeRequest) {
        return;
      }

      const data = response?.data ?? [];

      this.items = data.map((salesItem: SalesItem) => {
        return {
          ...salesItem,
          isLoading: false,
        };
      });

      this.maxPages = response?.maxPages ?? 0;

      this.currentPage = response?.currentPage ?? 0;

      this.totalItems = response?.totalItems ?? 0;
    },
    async fetchHasAnyItem() {
      const societyStore = useSocietyStore();
      this.hasAnyItem = null;

      if (this.controllers.hasItem && this.controllers.hasItem.signal)
        this.controllers.hasItem.abort();
      this.controllers.hasItem = new AbortController();

      const tab = this.queryProperties.tab;
      const isAccruals = societyStore.isAccruals;
      const tabBeforeRequest = this.queryProperties.tab;

      let response: any = null;
      if (tab === "Factures") {
        if (isAccruals) {
          await $silex()
            .invoice.getAccrualPaginated(
              {
                page: 1,
                limit: 1,
                isDescending: true,
              },
              undefined,
              this.controllers.hasItem.signal,
            )
            .then((res: BasePaginate<ReadAccrualInvoice>) => (response = res));
        } else {
          await $silex()
            .invoice.getCashBasedPaginated(
              {
                page: 1,
                limit: 1,
                isDescending: true,
              },
              undefined,
              this.controllers.hasItem.signal,
            )
            .then((res: BasePaginate<ReadCashBasedInvoice>) => (response = res));
        }
      } else if (tab === "Devis") {
        await $silex()
          .commercialDocument.getPaginated(
            {
              types: [EstimateInvoiceType.DEVIS, EstimateInvoiceType.BON],
              page: 1,
              limit: 1,
              isDescending: true,
              isDraft: false,
            },
            undefined,
            this.controllers.hasItem.signal,
          )
          .then((res: BasePaginate<ReadEstimateInvoice>) => (response = res));
      } else if (tab === "Brouillons") {
        await $silex()
          .commercialDocument.getPaginated(
            {
              types: [EstimateInvoiceType.FACTURE],
              page: 1,
              limit: 1,
              isDescending: true,
              isDraft: true,
            },
            undefined,
            this.controllers.hasItem.signal,
          )
          .then((res: BasePaginate<ReadEstimateInvoice>) => (response = res));
      }

      // if the tab has been changed before we have the response, don't use the response, because it can be for the wrong tab!
      // the response for the tab 1 can come after the response for the tab 2, when we go quick to tab 1 then to tab 2
      const tabAfterResponse = this.queryProperties.tab;

      if (tabAfterResponse !== tabBeforeRequest) {
        return;
      }

      const totalItems = response?.totalItems ?? 0;

      this.hasAnyItem = totalItems > 0;
    },
    async fetchStats() {
      const userStore = useUsersStore();
      this.stats = null;

      const { search, startDate, endDate } = this.queryProperties;
      const tabBeforeRequest = this.queryProperties.tab;

      let response: any = null;

      await $silex()
        .commercialDocument.getStatistics(userStore.currentSocietyId!, {
          ...(isDefined(startDate) ? { minDate: startDate } : {}),
          ...(isDefined(endDate) ? { maxDate: endDate } : {}),
          ...(isDefined(search) ? { search } : {}),
          isDraft: false,
        })
        .then((res: ReadCountDocumentByStatus) => (response = res))
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          response = [];
        });

      // if the tab has been changed before we have the response, don't use the response, because it can be for the wrong tab!
      // the response for the tab 1 can come after the response for the tab 2, when we go quick to tab 1 then to tab 2
      const tabAfterResponse = this.queryProperties.tab;

      if (tabAfterResponse !== tabBeforeRequest) {
        return;
      }

      this.stats = response ?? null;
    },
    async fetchSettings() {
      const userStore = useUsersStore();

      await $silex()
        .commercialSetting.getBySocietyId(userStore.currentSocietyId!)
        .then((res: ISocietyGescom | null) => {
          this.settings = res;
        })
        .catch((error) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
    async fetchCgv() {
      await $silex()
        .commercialSetting.getCgv()
        .then((res: string | null) => {
          this.cgv = res;
        })
        .catch((error) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
    async updateOrCreateSettings(payload: CreateCommercialSetting) {
      await $silex()
        .commercialSetting.updateOrCreate(payload)
        .then((updated) => {
          this.settings = updated;
          $notifier().open({
            type: "success",
            content: "Vos paramètres ont bien été enregistrées.",
          });
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          throw error;
        });
    },
    async updateOrCreateCgv(payload: CreateCgv) {
      await $silex()
        .commercialSetting.updateOrCreateCgv(payload)
        .then((updated) => {
          this.cgv = updated.cgv;
          $notifier().open({
            type: "success",
            content: "Vos conditions générales de ventes ont bien été enregistrées",
          });
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          throw error;
        });
    },
    async unexportSalesTransaction(item: ReadAccrualInvoice) {
      const indexItem = this.items?.findIndex((i) => i.id === item.id) ?? -1;

      if (this.items && indexItem !== -1) {
        this.items[indexItem].isLoading = true;

        try {
          const result = await $silex().transaction.annotate(item.id as number, {
            isAsset: item.isAsset,
            exported: null,
          });

          this.items[indexItem] = {
            ...result,
            isLoading: true,
          };

          const unlockedStore = useUnlockedStore();
          const userStore = useUsersStore();
          unlockedStore.fetchSaleDocuments(userStore.currentSocietyId!);

          $notifier().open({ type: "success", content: "Export modifié avec succès" });
        } catch (error) {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        }
        this.items[indexItem].isLoading = false;
      }
    },
    setSalesItem(salesItemId: number, salesItem: SalesItem & Loadable) {
      const item = this.items ?? [];
      const articleIndexToChange = item.findIndex((a) => a.id === salesItemId);

      if (articleIndexToChange > -1 && isDefined(this.items)) {
        this.items[articleIndexToChange] = salesItem;
      }
    },
    setUnpaidInvoice(itemId: number, recovery: IFile["paymentRecovery"]) {
      const items = this.items ?? [];
      const itemIndexToChange = items.findIndex((a) => a.id === itemId);

      if (itemIndexToChange > -1 && isDefined(this.items)) {
        // @ts-expect-error
        this.items[itemIndexToChange].file = {
          ...this.items[itemIndexToChange].file,
          paymentRecovery: recovery,
        };
      }
    },
    updateRecovery(id: number, status: PaymentRecoveryStatus) {
      const items = this.items ?? [];
      const indexInvoice = items.findIndex((item) => item.file?.paymentRecovery?.id === id);
      if (indexInvoice > -1 && isDefined(this.items)) {
        this.items[indexInvoice].file!.paymentRecovery!.status = status;
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
