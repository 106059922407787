import type { IGeneralLedgerAccount } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type GeneralLedgerQueryProperties = {
  page: number;
  limit: number;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  search: string | undefined;
  descending: boolean;
};

type GeneralLedgerState = {
  queryProperties: GeneralLedgerQueryProperties;
  data: IGeneralLedgerAccount[];
  noFilteredItems: IGeneralLedgerAccount[];
  currentPage: number | null;
  maxPages: number | null;
  totalItems: number | null;
  isLoading: boolean;
  hasAnyItem: null;
};

export const defaultGeneralLedgerQueryProperties = ref<GeneralLedgerQueryProperties>({
  search: undefined,
  page: 1,
  limit: 25,
  startDate: undefined,
  endDate: undefined,
  descending: false,
});

export const useGeneralLedgerStore = defineStore("generalLedger", {
  state: () =>
    ref<GeneralLedgerState>({
      queryProperties: cloneDeep(defaultGeneralLedgerQueryProperties.value),
      maxPages: null,
      currentPage: null,
      totalItems: null,
      isLoading: false,
      hasAnyItem: null,
      noFilteredItems: [],
      data: [],
    }),
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultGeneralLedgerQueryProperties.value);
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
      this.data = [];
      this.noFilteredItems = [];
    },
    async fetch(): Promise<void> {
      this.isLoading = true;
      try {
        const exerciceStore = useExerciceStore();
        const dayjs = useDayjs();

        const result = await $silex().generalLedger.getBySociety({
          dateStart:
            this.queryProperties.startDate ??
            dayjs(exerciceStore.defaultExercice.startDate ?? "1970-01-01").format("YYYY-MM-DD"),
          dateEnd:
            this.queryProperties.endDate ??
            dayjs(exerciceStore.defaultExercice.endDate ?? "1970-01-01").format("YYYY-MM-DD"),
          page: this.queryProperties.page,
          limit: this.queryProperties.limit,
          search: this.queryProperties.search,
          descending: this.queryProperties.descending,
        });

        this.currentPage = result.currentPage;
        this.maxPages = result.maxPages;
        this.totalItems = result.totalItems;
        this.data = result.data;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
    async fetchNoFiltered(): Promise<void> {
      try {
        const result = await $silex().generalLedger.getBySociety({
          dateStart: "1970-01-01",
          dateEnd: "2170-01-01",
          page: 1,
          limit: 10000,
        });

        this.noFilteredItems = result.data;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
