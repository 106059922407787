import type { IFile, ReadIncomeAllocation, TypeAndAmount } from "@silexpert/core";

type IncomeAllocationState = {
  incomeAllocation: ReadIncomeAllocation | null;
  typeAndAmount: TypeAndAmount | null;
  file: IFile | null;
};

export const useIncomeAllocationStore = defineStore("incomeAllocation", {
  state: () =>
    ref<IncomeAllocationState>({
      incomeAllocation: null,
      typeAndAmount: null,
      file: null,
    }),
  actions: {
    reset() {
      this.incomeAllocation = null;
      this.typeAndAmount = null;
      this.file = null;
    },
    async fetchFromExercice(idExercice: number) {
      try {
        const [incomeAllocation, typeAndAmount] = await Promise.all([
          $silex().incomeAllocation.get(idExercice),
          $silex().incomeAllocation.getTypeAndAmount(idExercice),
          this.fetchAnnualGeneralMeeting(idExercice),
        ]);
        this.incomeAllocation = incomeAllocation;
        this.typeAndAmount = typeAndAmount;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchAnnualGeneralMeeting(idExercice: number) {
      try {
        this.file = await $silex().incomeAllocation.getAnnualGeneralMeeting(idExercice);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async delete(idExercice: number) {
      try {
        await $silex().incomeAllocation.delete(idExercice);
        await this.fetchFromExercice(idExercice);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIncomeAllocationStore, import.meta.hot));
}
