import type { IGedFile, ISocietyConfigBalanceSheet } from "@silexpert/core";
import { DocumentConfigurationType, JuridicalDepositDocumentType } from "@silexpert/core";

type BusinessCreationState = {
  isLoading: boolean;
  documents: ISocietyConfigBalanceSheet[];
  isLoadingSigns: boolean;
  signs: IGedFile[];
  files: IGedFile[];
};

export const useBusinessCreationStore = defineStore("businessCreation", {
  state: () =>
    ref<BusinessCreationState>({
      isLoading: false,
      documents: [],
      isLoadingSigns: false,
      signs: [],
      files: [],
    }),
  getters: {},
  actions: {
    reset() {
      this.isLoading = true;
      this.documents = [];
      this.signs = [];
      this.files = [];
    },
    async fetchDocuments() {
      try {
        this.isLoading = true;
        const societyStore = useSocietyStore();

        this.documents = await $silex().societyConfigBalanceSheet.getAll(
          societyStore.society!.id!,
          {
            attributes: [
              "id",
              "state",
              "stateClient",
              "idSociety",
              "idExercice",
              "idBalancesheet",
              "idDepositDocumentType",
              "configurationType",
              "createdAt",
            ],
            include: ["user"],
            configurationType: DocumentConfigurationType.JURIDICAL,
          },
        );
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
    async fetchSigns() {
      try {
        this.isLoadingSigns = true;
        const societyStore = useSocietyStore();
        const files = await $silex().library.getAll({
          idSociety: societyStore.society!.id!,
          typeGedFolder: JuridicalDepositDocumentType.map((jddt) => jddt.idGedFolder),
          include: ["fileFromGed", "customerSignature"],
        });

        this.signs = files.filter((d) => isDefined(d.customerSignature));
        this.files = files;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
      this.isLoadingSigns = false;
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
