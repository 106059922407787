import type { BasePaginate, IProduct, UpdateProduct, VatType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";
export type ProductsQueryProperties = {
  tab: "products" | null;
  search: string | undefined;
  page: number;
  limit: number;
  type: number | null;
  // the SP is opened if productId is defined
  productId: number | null;
};

export const defaultProductsQueryProperties = ref<ProductsQueryProperties>({
  tab: null,
  search: undefined,
  type: null,
  page: 1,
  limit: 25,
  productId: null,
});

export const useProductStore = defineStore("products", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultProductsQueryProperties.value) as ProductsQueryProperties,
      products: null as (IProduct & Loadable)[] | null,
      allProducts: [] as IProduct[], // without filters
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      isLoading: false as boolean,
      hasAnyItem: null as boolean | null,
      checkedIds: [] as number[],
      controllers: { hasAny: null, products: null } as {
        hasAny: AbortController | null;
        products: AbortController | null;
      },
    };
  },
  getters: {},
  actions: {
    async fetchProducts() {
      this.isLoading = true;

      const { search, page, limit, type } = this.queryProperties;
      if (this.controllers.products && this.controllers.products?.signal) {
        await this.controllers.products.abort();
        this.controllers.products = null;
      }
      this.controllers.products = new AbortController();

      try {
        await $silex()
          .product.getPaginated(
            { page, limit },
            { search, ...(isDefined(type) && { type }) },
            undefined,
            this.controllers.products.signal,
          )
          .then((res: BasePaginate<IProduct>) => {
            const data = res?.data ?? [];
            const maxPages = res?.maxPages ?? 0;
            const currentPage = res?.currentPage ?? 0;
            const totalItems = res?.totalItems ?? 0;

            const products = data.map((article: IProduct) => {
              return {
                ...article,
                isLoading: false,
              };
            });

            this.products = products;
            this.maxPages = maxPages;
            this.currentPage = currentPage;
            this.totalItems = totalItems;
          });
      } catch (error: any) {
        if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
    async fetchAllProducts() {
      await $silex()
        .product.getAll()
        .then((res: IProduct[]) => (this.allProducts = res))
        .catch((error) => {
          this.allProducts = [];
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          throw error;
        });
    },
    async updateProduct({ productId, payload }: { productId: number; payload: UpdateProduct }) {
      const serverInfoStore = useServerInfoStore();

      this.setProductIsLoading(productId, true);
      let product: IProduct | null = null;

      try {
        product = await $silex().product.update(productId, payload);
      } catch (error: any) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
        product = null;
      }

      // The api do not set the vat object but we need it to calculate the ttc price
      const vats = serverInfoStore.getVats ?? [];
      const matchingVat = vats.find((vat: VatType) => vat.idTva === product?.idVat);

      const article = {
        ...product,
        vat: matchingVat,
      } as IProduct;

      this.setProduct(productId, article);
      this.setProductIsLoading(productId, false);

      $notifier().open({
        content: "Changement enregistré",
        type: "success",
      });
    },
    async fetchHasAnyItem() {
      this.hasAnyItem = null;

      if (this.controllers.hasAny && this.controllers.hasAny?.signal) {
        this.controllers.hasAny.abort();
      }
      this.controllers.hasAny = new AbortController();

      try {
        const response = await $silex().product.getPaginated(
          { page: 1, limit: 1 },
          {},
          undefined,
          this.controllers.hasAny.signal,
        );
        const totalItems = response?.totalItems ?? 0;
        const hasAnyItem = totalItems > 0;
        this.hasAnyItem = hasAnyItem;
      } catch (error: any) {
        if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    setProduct(productId: number, article: IProduct | (IProduct & { isLoading: boolean })) {
      const products = this.products ?? [];
      const articleIndexToChange = products.findIndex((a) => a.id === productId);

      if (articleIndexToChange > -1 && isDefined(this.products)) {
        this.products[articleIndexToChange] = article as IProduct & { isLoading: boolean };
      }
    },
    setProductIsLoading(productId: number, isLoading: boolean) {
      const products = this.products ?? [];
      const articleIndexToChange = products.findIndex((a) => a.id === productId);

      if (articleIndexToChange > -1 && isDefined(this.products)) {
        this.products[articleIndexToChange].isLoading = isLoading;
      }
    },
    reset() {
      this.queryProperties = cloneDeep(defaultProductsQueryProperties.value);
      this.products = null;
      this.allProducts = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
      this.checkedIds = [];
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
